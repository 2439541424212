import React from 'react';
import WithTranslator from '../../../providers/WithTranslator';
import apiService from '../../../services/apiService';
import CommonPopup from './CommonPopup';

const defaultState = {
    successRegistration: false,
    isSignatureExpiredError: false,
    isSignatureInvalidError: false,
}


class RegistrationPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = defaultState
    }

    componentDidMount() {
        const params = window.location.search.substring(1)
        if (params) {
            const searchParams = new URLSearchParams(params);
            const data = Object.assign({},
                searchParams.get('user_id') && {'user_id' : searchParams.get('user_id')},
                searchParams.get('timestamp') && {'timestamp' : searchParams.get('timestamp')},
                searchParams.get('signature') && {'signature' : searchParams.get('signature')},
            );
            if (Object.keys(data).length === 3) {
                apiService.verifyRegistration(data).then(state => {
                    if (!state?.errors) {
                        state.successRegistration = true;
                    } else {
                        if (state?.errors?.detail === 'Signature expired') {
                            state.isSignatureExpiredError = true;
                        }
                        if (state?.errors?.detail === 'Invalid signature') {
                            state.isSignatureInvalidError = true;
                        }
                    }
                    this.setState({...state}, () => window.scrollTo({top: 0}))
                });
            }
        }
    }

    handleClose = () => {
        this.setState(defaultState)
    }

    render() {
        const translator = this.props.translator;
        return (
            <CommonPopup
                success={{
                    isSuccess: this.state.successRegistration,
                    title: translator('loginUserPart.registerUser.registered.title'),
                    info: translator('loginUserPart.registerUser.registered.info'),
                    onClosePath: '/login',
                }}
                error={{
                    isSignatureExpiredError: this.state.isSignatureExpiredError,
                    isSignatureInvalidError: this.state.isSignatureInvalidError,
                    title: translator('loginUserPart.registerUser.popup.error.title'),
                    expiredInfo: translator('loginUserPart.registerUser.popup.error.signatureExpired'),
                    info: translator('loginUserPart.registerUser.popup.error.signatureError'),
                    onClose: this.handleClose,
                    onClosePath: '/'
                }}
            />
        )
    }
}

export default WithTranslator(RegistrationPopup);
