import {RestRequest} from './requestService';
import {PAYOUT_STATUS, RESULT_STATUS} from '../constants';

const TIMEOUT = 500;

const executeTransferRequest = (url, data, uri) =>
    RestRequest.post(url, {}, data)
        .then(response => {
            const trId = response.data?.trId;
            trId && history.pushState(null, null, uri + `?trId=${encodeURIComponent(trId)}`);
            if (response.data.status === PAYOUT_STATUS.PENDING) {
                return new Promise(resolve =>
                    setTimeout(() => resolve(getStatus(trId)), TIMEOUT));
            }
            return getProcessedResponse(response);
        })
        .catch(reason => reason?.response?.status
            ? getProcessedResponse(reason)
            : new Promise(resolve =>
                setTimeout(() => resolve(executeTransferRequest(url, data, uri)), TIMEOUT))
        );

const getProcessedResponse = response => {
    switch (response.data?.status) {
        case PAYOUT_STATUS.WAITING_3DS:
            const _3ds = response.data['3ds'];
            return {_3ds};
        case PAYOUT_STATUS.TRANSFERED:
            return {status: RESULT_STATUS.SUCCESS, trId: response.data.trId};
        case PAYOUT_STATUS.WAITING_CONTINUE_TRANSFER:
            return {status: RESULT_STATUS.CODE, trId: response.data.trId};
        case PAYOUT_STATUS.FAILED:
        case PAYOUT_STATUS.FAILED_CONTINUE_TRANSFER:
        default:
            return {status: RESULT_STATUS.ERROR};
    }
}

const executeLoginRequest = (method, url, data, headers=null) =>
    RestRequest[method](url, {}, data, headers)
        .then(response => {
            return response.data
        })
        .catch(reason => reason?.response?.status
            ? {errors: reason.response.data}
            : {errors: reason.message}
            // ToDo сценарий с повтором запроса
            // : new Promise(resolve =>
            //     setTimeout(() => resolve(executeLoginRequest(url, data)), TIMEOUT))
        );

function getAuthorizationHeader() {
    const token = localStorage.getItem('access')
    return {"Authorization":  "Bearer " + token}
}

function getRefreshToken() {
    return {refresh: localStorage.getItem('refresh') || ''}
}

const executeAccountRequest = (method, url, {params={}, data={}} = {}) => {
    return RestRequest[method](url, params, data, getAuthorizationHeader())
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(error => {
            return Promise.reject(error.response);
        });
}

const sendFeedback = data =>
    RestRequest.post(configs.apiUrl + '/sendFeedback', {}, data);

const startTransfer = data => executeTransferRequest(configs.apiUrl + '/startTransfer', data, 'transfer-send');

const continueTransfer = data => executeTransferRequest(configs.apiUrl + '/continueTransfer', data, 'transfer-send');

const getStatus = trId => executeTransferRequest(configs.apiUrl + '/getStatus', {trId}, 'transfer-receive');

const registerUser = data => executeLoginRequest(
    'post',
    configs.djangoApiUrl + '/register/',
    data
);

const checkLimit = () => executeAccountRequest(
    'get',
    configs.djangoApiUrl + '/current-limit/'
)

const verifyRegistration = data => executeLoginRequest(
    'post',
    configs.djangoApiUrl +'/verify-registration/',
    data
);

const loginUser = data => executeLoginRequest(
    'post',
    configs.djangoApiUrl + '/auth/token/',
    data
);

const getNewTfa = () => executeAccountRequest(
    'get',
    configs.djangoApiUrl + '/tfa/',
);

const verifyTfa = (data) => executeAccountRequest(
    'post',
    configs.djangoApiUrl + '/auth/tfa/',
    {data: data},
);

const enableTfa = (data) => executeAccountRequest(
    'put',
    configs.djangoApiUrl + '/tfa/',
    {data: data},
);

const sendResetPasswordLink = (data) => executeLoginRequest(
    'post',
    configs.djangoApiUrl + '/send-reset-password-link/',
    data
);

const resetPassword = (data) => executeLoginRequest(
    'post',
    configs.djangoApiUrl + '/reset-password/',
    data
);

const checkApplicant = () => executeAccountRequest(
    'get',
    configs.djangoApiUrl + '/kyc/applicant/',
)

const getKYCToken = () => executeAccountRequest(
    'get',
    configs.djangoApiUrl + '/kyc/access-token/',
)

const getTransferList = (params) => executeAccountRequest(
    'get',
    configs.djangoApiUrl + '/transfers/',
    {params: params}
)

const getTransfer = trId => executeAccountRequest(
    'get',
    configs.djangoApiUrl + '/transfers/' + trId + '/',
)

const getProfileInfo = (params) => executeAccountRequest(
    'get',
    configs.djangoApiUrl + '/profile/',
    {params: params}
)

const changePassword = (data) => executeAccountRequest(
    'put',
    configs.djangoApiUrl + '/change-password/',
    {data: data}
)

const getCurrentTfa = () => executeAccountRequest(
    'get',
    configs.djangoApiUrl + '/profile-tfa/',
)

const generateNewTFA = () => executeAccountRequest(
    'post',
    configs.djangoApiUrl + '/profile-tfa/',
)

const refreshToken = () => executeAccountRequest(
    'post',
    configs.djangoApiUrl + '/auth/token/refresh/',
    {data: getRefreshToken()}
)

export default {
    sendFeedback,
    startTransfer,
    continueTransfer,
    getStatus,
    checkLimit,
    registerUser,
    verifyRegistration,
    loginUser,
    getNewTfa,
    verifyTfa,
    enableTfa,
    sendResetPasswordLink,
    resetPassword,
    checkApplicant,
    getKYCToken,
    getTransferList,
    getTransfer,
    getProfileInfo,
    changePassword,
    getCurrentTfa,
    generateNewTFA,
    refreshToken,
}
