import React from 'react';
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import {Button} from "@material-ui/core";
import WithTranslator from '../../../../providers/WithTranslator';
import WithTokenSupport from "../../../../providers/WithTokenSupport";
import DialogForm from "../../../common/DialogForm";
import {UNAUTHORIZED} from "../../../../constants";

import background from '../../../../resources/main-background.png';
import './MainPart.scss';

class MainPart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            warning: false
        }
    }

    handleWarning = (value) => {
        this.setState({warning: value});
    }

    render() {
        const translator = this.props.translator;
        return (
            <>
                <a name="transfer" />
                <div className='main-part' id='mainPartBlock'>
                    <div className='content'>
                        <span className='title'>{ReactHtmlParser(translator('mainPart.title'))}</span>

                        <div className='menu'>
                            {this.props.userStatus === UNAUTHORIZED
                                ?
                                <Button className='primary' onClick={() => this.handleWarning(true)}>
                                    {translator('transferPart.menu.btn.makeTransfer')}
                                </Button>
                                :
                                <Link to={'/transfer-send'}>
                                    <Button className='primary'>
                                        {translator('transferPart.menu.btn.makeTransfer')}
                                    </Button>
                                </Link>
                            }
                            <Link to={'/transfer-receive'}>
                                <Button className='secondary'>
                                    {translator('transferPart.menu.btn.receiveTransfer')}
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <DialogForm
                        open={this.state.warning}
                        onCancel={() => this.handleWarning(false)}
                        onSubmitPath={'/register'}
                        title={translator('mainPart.sendTransfer.warning.title')}
                        info={translator('mainPart.sendTransfer.warning.info')}
                        submitName={translator("mainPart.sendTransfer.warning.btn.submit")}
                    />
                    <img src={background} alt=''/>
                </div>
            </>
        )
    }

}

export default WithTokenSupport(WithTranslator(MainPart));
