import React from 'react';
import InfoForm from '../../common/InfoForm';

class CommonPopup extends React.Component {
    getTexts() {

        const title = this.props.error.title
        if (this.props.error.isSignatureExpiredError) {
            return {
                'title': title,
                'info': this.props.error.expiredInfo,
            }
        }
        if (this.props.error.isSignatureInvalidError) {
            return {
                'title': title,
                'info': this.props.error.info,
            }
        }
    }

    render() {
        return (
            <>
                <InfoForm
                    open={this.props.error.isSignatureExpiredError || this.props.error.isSignatureInvalidError}
                    onClose={this.props.error.onClose}
                    onClosePath={this.props.error.onClosePath}
                    {...this.getTexts()}
                />
                <InfoForm
                    open={this.props.success.isSuccess}
                    onClosePath={this.props.success.onClosePath}
                    title={this.props.success.title}
                    info={this.props.success.info}
                />
            </>
        )
    }
}

export default CommonPopup;
