import React from 'react';
import {Link} from "react-router-dom";
import {Button, Checkbox, Paper} from "@material-ui/core";
import TextInput from "../../textInpit/TextInput";
import {ReactComponent as IconBack} from "../../../resources/icons/icon-back.svg";
import WithTranslator from '../../../providers/WithTranslator';
import WithCurrentPage, {pages} from "../../../providers/WithCurrentPage";
import apiService from "../../../services/apiService";
import FieldsHelper from "../../common/FieldsHelper";
import FValidator from '../../common/FieldsValidator';
import InfoForm from "../../common/InfoForm";


const defaultFields = {
    email: '',
    password: '',
    password_confirm: '',
    first_name: '',
    last_name: '',
    agree: false
};

const requiredFields = ['email', 'password', 'password_confirm', 'agree'];

class RegisterUser extends React.Component {
    constructor(props) {
        super(props);

        this.FHelper = new FieldsHelper(this);
        this.state = {
            fields: defaultFields,
            errors: {},
            success: false,
        };
    }

    componentDidMount() {
        this.props.setCurrentPage(pages.Other);
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    onSubmit = () => {
        const password = this.state.fields.password;
        const password_confirm = this.state.fields.password_confirm;
        const errors = {
            email: FValidator.isEmailValid(this.state.fields.email).detail,
            password: FValidator.isPasswordValid(this.state.fields.password).detail,
            password_confirm: password !== password_confirm ? 'Passwords don\'t match' : '',
            first_name: FValidator.isNameValid(this.state.fields.first_name).detail,
            last_name: FValidator.isNameValid(this.state.fields.last_name).detail
        };
        if (Object.values(errors).some(i => !!i)) {
            this.setState({errors: {...errors}});
        } else {
            this.send();
        }
    }

    send = () => {
        window.scrollTo({top: 0});
        const data = {
            'email': this.state.fields.email,
            'password': this.state.fields.password,
            'password_confirm': this.state.fields.password_confirm,
            'first_name': this.state.fields.first_name,
            'last_name': this.state.fields.last_name
        }
        apiService.registerUser(data).then(state => {
            if (state?.errors) {
                state.success = false;
            } else {
                state.success = true;
                state.errors = {};
            }
            this.setState({...state}, () => window.scrollTo({top: 0}))
        });
    }


    isSubmitDisabled = () => {
        const isErrors = Object.values(this.state.errors).some(e => !!e)
        const isAllRequired = !Object.entries(this.state.fields).every(([k, v]) => {
            return (requiredFields.includes(k) ? !!v : true)
        });
        return isErrors || isAllRequired
    }

    handleKeyDown = (e) => {
        if (e.key !== 'Enter') return;
        if (!this.isSubmitDisabled()){
            this.onSubmit();
        }
    }

    render() {
        const translator = this.props.translator;
        return (
            <div className='transfer-part' id='loginPart'>
                <div className='transfer-part__inner'>
                    <div className={`container`}>
                        <Link to={'/'} className='back-link'>
                            <IconBack/>
                            <span>{translator('backLink.toMain')}</span>
                        </Link>
                        <div className={`transfer`}>
                            <div>
                                <span className='header'>
                                    {translator('loginUserPart.registerUser.title')}
                                </span>

                                <div className='cards'>
                                    <Paper className='card'>
                                        <TextInput label={`${translator('loginUserPart.form.email')} *`}
                                                   withErrorText autoComplete='off'
                                                   className='margin-bottom'
                                                   placeholder='example@email.com'
                                                   value={this.state.fields.email}
                                                   error={!!this.state.errors.email}
                                                   errorText={this.state.errors.email}
                                                   onChange={this.FHelper.onEmailChange}
                                                   onBlur={this.FHelper.onEmailBlur}
                                        />
                                        <TextInput label={`${translator('loginUserPart.form.password')} *`}
                                                   type='password' autoComplete='new-password' withErrorText
                                                   placeholder='**********'
                                                   error={!!this.state.errors.password}
                                                   errorText={this.state.errors.password}
                                                   value={this.state.fields.password}
                                                   onChange={this.FHelper.onPasswordChange}
                                                   onBlur={this.FHelper.onRegisterPasswordBlur}/>
                                        <div className='helpText margin-bottom'>{translator('loginUserPart.registerUser.step1.passwordPrompt')}</div>
                                        <TextInput label={`${translator('loginUserPart.form.confirmPassword')} *`}
                                                   type='password' autoComplete='new-password' withErrorText
                                                   className='margin-bottom'
                                                   placeholder='**********'
                                                   error={!!this.state.errors.password_confirm}
                                                   errorText={this.state.errors.password_confirm}
                                                   value={this.state.fields.password_confirm}
                                                   onChange={this.FHelper.onPasswordConfirmChange}
                                                   onBlur={(e) => {this.FHelper.onPasswordConfirmBlur(e, this.state.fields.password)}}/>
                                        <TextInput label={translator('loginUserPart.form.firstName')}
                                                   withErrorText
                                                   className='margin-bottom'
                                                   placeholder='JOHN'
                                                   error={!!this.state.errors.first_name}
                                                   errorText={this.state.errors.first_name}
                                                   value={this.state.fields.first_name}
                                                   onChange={this.FHelper.onFirstNameChange}
                                                   onBlur={this.FHelper.onFirstNameBlur}/>
                                        <TextInput label={translator('loginUserPart.form.lastName')}
                                                   withErrorText
                                                   className='margin-bottom'
                                                   placeholder='SMITH'
                                                   error={!!this.state.errors.last_name}
                                                   errorText={this.state.errors.last_name}
                                                   value={this.state.fields.last_name}
                                                   onChange={this.FHelper.onLastNameChange}
                                                   onBlur={this.FHelper.onLastNameBlur}/>
                                        <div className='agree-part margin-bottom'>
                                            <Checkbox checked={this.state.fields.agree}
                                                      onChange={() =>
                                                          this.setState({
                                                              fields: {
                                                                  ...this.state.fields,
                                                                  agree: !this.state.fields.agree
                                                              }
                                                          })
                                                      }/>
                                            <span>
                                                {translator('loginUserPart.registerUser.step1.agree1')}
                                                <a href={`/terms-webtranspay-${this.props.language}.pdf`} target='_blank'>
                                                    {translator('loginUserPart.registerUser.step1.terms')}
                                                </a>&nbsp;
                                                {translator('loginUserPart.registerUser.step1.agree2')}
                                                <a href={`/policy-webtranspay-${this.props.language}.pdf`} target='_blank'>
                                                    {translator('loginUserPart.registerUser.step1.policy')}
                                                </a>
                                            </span>
                                        </div>
                                        <div className="center">
                                            <Link to={'/login'}>
                                                {translator('loginUserPart.link.haveAccount')}
                                            </Link>
                                        </div>
                                    </Paper>
                                </div>
                            </div>
                            <div className='center'>
                                <Button className='submit primary' onClick={this.onSubmit}
                                        disabled={this.isSubmitDisabled()}>
                                    {translator('loginUserPart.registerUser.step1.btn.submit')}
                                </Button>
                            </div>
                        </div>
                        <InfoForm
                            open={this.state.success}
                            onClosePath={'/'}
                            title={translator('loginUserPart.registerUser.waitEmail.title')}
                            info={translator('loginUserPart.registerUser.waitEmail.info')}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default WithCurrentPage(WithTranslator(RegisterUser));
