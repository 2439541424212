import React from 'react';
import TransferSendForm from "../../transfer/TransferSend";
import withCurrentPage, {pages} from "../../../providers/WithCurrentPage";
import {withValidateLogin} from "../../../providers/WithValidateLogin";
import WithCheckKYC from "../../../providers/WithCheckKYC";


class MainTransferSendPage extends React.Component {

    componentDidMount() {
        this.props.setCurrentPage(pages.Other);
    }

    render() {
        return (
            <div className='transfer-part' id='transferPart'>
                <div className='transfer-part__inner'>
                    <TransferSendForm/>
                </div>
            </div>
        )
    }
}

export default withValidateLogin(WithCheckKYC(withCurrentPage(MainTransferSendPage)));
