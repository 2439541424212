import React from 'react';
import {Link} from "react-router-dom";
import {Button, Modal} from '@material-ui/core';
import WithTranslator from '../../providers/WithTranslator';
import {ReactComponent as IconCancel} from "../../resources/icons/icon-cancel.svg";

class InfoForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const translator = this.props.translator;
        return (
            <Modal
                open={this.props.open}
                className='contacts-part'
            >
                <form className='dialog-form'>
                    <div className='header center'>
                        <div className='empty'/>
                        <div className='title'>
                            <span className='h3'>{this.props.title}</span>
                        </div>
                        <Link to={this.props.onClosePath} className='close-btn' onClick={this.props.onClose}>
                            <IconCancel/>
                        </Link>
                    </div>
                    <div className={'center'}>
                        <div className={'margin-bottom body-text'}>{this.props.info}</div>
                        <Link to={this.props.onClosePath} onClick={this.props.onClose}>
                            <Button className='primary'>
                                {translator('infoForm.btn.ok')}
                            </Button>
                        </Link>
                    </div>
                </form>
            </Modal>
        )
    }
}

export default WithTranslator(InfoForm);
