import React from 'react';
import TransferGetForm from '../../transfer/TransferReceive';
import withCurrentPage from "../../../providers/WithCurrentPage";
import {pages} from "../../../providers/WithCurrentPage";
import WithCheckKYC from "../../../providers/WithCheckKYC";


class MainTransferReceivePage extends React.Component {
    componentDidMount() {
         this.props.setCurrentPage(pages.Other);
    }

    render() {
        return (
            <div className='transfer-part' id='transferPart'>
                <div className='transfer-part__inner'>
                    <TransferGetForm/>
                </div>
            </div>
        )
    }
}

export default WithCheckKYC(withCurrentPage(MainTransferReceivePage));
