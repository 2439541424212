import React from 'react';
import {Outlet} from "react-router-dom";
import Header from "./header/Header";
import ContactsPart from "./contactsPart/ContactsPart";
import Footer from "./footer/Footer";

class MainLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            feedback: false,
        }
    }

    openFeedback = () => this.setState({feedback: true});

    closeFeedback = () => this.setState({feedback: false});

    render() {
        return (
            <div className='app-container'>
                <Header/>
                <Outlet />
                <ContactsPart open={this.state.feedback} closeFeedback={this.closeFeedback}/>
                <Footer openFeedback={this.openFeedback}/>
            </div>
        )
    }
}

export default MainLayout;
