import React from 'react';
import TransferGetForm from '../../../transfer/TransferReceive';
import WithCheckKYC from "../../../../providers/WithCheckKYC";


class AccountTransferReceivePage extends React.Component {
     render() {
        return (
            <div className="transfer-part account-part">
                <div className='transfer-part__inner'>
                    <TransferGetForm/>
                </div>
            </div>
        )
    }
}

export default WithCheckKYC(AccountTransferReceivePage);
