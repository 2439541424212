import React from 'react';
import {Link} from "react-router-dom";
import WithTranslator from '../../../providers/WithTranslator';
import {ReactComponent as Logo1} from "../../../resources/logo.svg";

import { ReactComponent as TransfersIcon } from "../../../resources/icons/menu-transfers.svg";
import { ReactComponent as SendIcon } from "../../../resources/icons/menu-send.svg";
import { ReactComponent as ReceiveIcon } from "../../../resources/icons/menu-receive.svg";
import { ReactComponent as ProfileIcon } from "../../../resources/icons/menu-profile.svg";
import {ACCOUNT_PAGES} from "../../../constants";


import "./Menu.scss"

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currPage: props.currAccountPage,
        }
    }

    render() {
        const translator = this.props.translator;
        return (
            <div className="account-menu">
                <Link to={'/'} >
                    <div className='account-logo'>
                        <Logo1/>
                        WEBTRANSPAY
                    </div>
                </Link>

                <div className="account-link-container">
                    <Link to="/account/transfers"
                          className={`link${this.state.currPage === ACCOUNT_PAGES.transfers ? " currPage" : ""}`}
                          onClick={() => this.setState({currPage: ACCOUNT_PAGES.transfers})}
                    >
                        <TransfersIcon /> &nbsp;
                        <span>{translator("account.menu.transfers")}</span>
                    </Link>
                    <Link
                      to="/account/send"
                      className={`link${this.state.currPage === ACCOUNT_PAGES.send ? " currPage" : ""}`}
                      onClick={() => this.setState({currPage: ACCOUNT_PAGES.send})}
                    >
                        <SendIcon /> &nbsp;
                        <span>{translator("account.menu.send")}</span>
                    </Link>
                    <Link
                      to="/account/receive"
                      className={`link${this.state.currPage === ACCOUNT_PAGES.receive ? " currPage" : ""}`}
                      onClick={() => this.setState({currPage: ACCOUNT_PAGES.receive})}
                    >
                        <ReceiveIcon /> &nbsp;
                        <span>{translator("account.menu.receive")}</span>
                    </Link>
                    <Link
                      to="/account/profile"
                      className={`link${this.state.currPage === ACCOUNT_PAGES.profile ? " currPage" : ""}`}
                      onClick={() => this.setState({currPage: ACCOUNT_PAGES.profile})}>
                        <ProfileIcon /> &nbsp;
                        <span>{translator("account.menu.profile")}</span>
                    </Link>
                </div>
            </div>
        )
    }
}

export default WithTranslator(Menu);
