import React from 'react';
import QRCode from "qrcode";
import TextInput from '../textInpit/TextInput';
import WithTranslator from '../../providers/WithTranslator';
import WithTokenSupport from "../../providers/WithTokenSupport";
import FieldsHelper from '../common/FieldsHelper';


class ShowTFA extends React.Component {
    constructor(props) {
        super(props);

        this.FHelper = new FieldsHelper(this);
        // ToDo errors не обрабатываются - вырезать?
        this.state = {
            tfa_secret: '',
            errors: {},
        };
    }

    componentDidMount() {
        this.props.sendRequest(this.props.getTFARequest).then((response) => {
            const new_state = {};
            if (!response?.errors) {
                new_state.errors = {};
                new_state.tfa_secret = response.tfa_uri.split("?secret=")[1].split("&")[0];
                const canvas = document.getElementById("qr-canvas");
                QRCode.toCanvas(canvas, response.tfa_uri, error => error && console.error(error));
            }
            this.setState({...new_state}, () => window.scrollTo({top: 0}))
        })
    }

    render() {
        const translator = this.props.translator;

        return (
            <>
                <div className='margin-bottom'>
                    {translator('showTFA.info.QR')}
                </div>
                <div className='center'><canvas id='qr-canvas'></canvas></div>
                <p>{translator('showTFA.info.Key')}</p>
                <TextInput label={translator('loginUserPart.form.tfa')}
                           withCopy disabled
                           value={this.state.tfa_secret}
                           onChange={this.FHelper.onTfaSecretChange}
                />
            </>
        )
    }
}

export default WithTokenSupport(WithTranslator(ShowTFA));
