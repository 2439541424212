import React from 'react';
import TransferSendForm from "../../../transfer/TransferSend";
import WithCheckKYC from "../../../../providers/WithCheckKYC";


class AccountTransferSendPage extends React.Component {

    render() {
        return (
            <div className="transfer-part account-part">
                <div className='transfer-part__inner'>
                    <TransferSendForm/>
                </div>
            </div>
        )
    }
}

export default WithCheckKYC(AccountTransferSendPage);
