import React from 'react';
import {Link, Navigate} from "react-router-dom";
import {Paper} from "@material-ui/core";
import apiService from "../../../../services/apiService";
import {withParams} from "../../../../services/hooks"
import withTranslator from "../../../../providers/WithTranslator";
import withCheckKYC from "../../../../providers/WithCheckKYC";
import withTokenSupport from "../../../../providers/WithTokenSupport";
import {ReactComponent as IconBack} from "../../../../resources/icons/icon-back.svg";
import {STATUS_COLORS} from "../../../../constants";

import "./TransferDetailPage.scss"

class TransferDetailPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            transaction: {},
            has_error: false
        }
    }

    formatDecimal(value) {
        let floatValue = parseFloat(value);
        return floatValue.toFixed(2);
    }

    getTotalAmount() {
        let amount = parseFloat(this.state.transaction.amount);
        let fee = parseFloat(this.state.transaction.fee);
        return (amount + fee).toFixed(2);
    }

    getTransaction() {
        let { id } = this.props.params;
        this.props.sendRequest(apiService.getTransfer, id).then(response => {
            if (!response?.errors){
                this.setState(() => {return {
                    transaction: response
                }})
            } else {
                this.setState(() => {return {
                    hasError: true
                }})
            }
        })
    }

    componentDidMount() {
        this.getTransaction();
    }

    render() {
        const translator = this.props.translator;

        return (
            <div className='transfer-part account-part container'>
                <Link to={'/account/transfers'} className='back-link'>
                    <IconBack/>
                    <span>{translator('backLink.back')}</span>
                </Link>
                {this.state.hasError && <Navigate to={'/account/transfers'}/>}
                <div className='transfer'>
                    <span className='header'>{translator('transferDetail.title')}</span>
                    <div className='cards centralize'>
                        <Paper className='card one-card'>
                            {Object.entries(this.state.transaction).length > 0
                                ?
                                <>
                                    <TransferDetailPageLine
                                        label={translator('transferDetail.card.transactionId')}
                                        value={this.state.transaction.transaction_id}
                                    />
                                    <TransferDetailPageLine
                                        label={translator('transferDetail.card.created')}
                                        value={this.state.transaction.created}
                                    />
                                    <TransferDetailPageLine
                                        label={translator('transferDetail.card.transferAmount')}
                                        value={this.formatDecimal(this.state.transaction.amount)}
                                    />
                                    <TransferDetailPageLine
                                        label={translator('transferDetail.card.fee')}
                                        value={this.formatDecimal(this.state.transaction.fee)}
                                    />
                                    <TransferDetailPageLine
                                        label={translator('transferDetail.card.totalAmount')}
                                        value={this.getTotalAmount()}
                                    />
                                    <TransferDetailPageLine
                                        label={translator('transferDetail.card.currency')}
                                        value={this.state.transaction.currency}
                                    />
                                    <TransferDetailPageLine
                                        label={translator('transferDetail.card.status')}
                                        value={this.state.transaction.status}
                                        style={{color: STATUS_COLORS[this.state.transaction.status] || ""}}
                                    />
                                    <TransferDetailPageLine
                                        label={translator('transferDetail.card.cardNumber')}
                                        value={this.state.transaction.card_number}
                                    />
                                    <TransferDetailPageLine
                                        label={translator('transferDetail.card.cardholder')}
                                        value={this.state.transaction.card_holder}
                                    />
                                    {this.state.transaction.transfercode &&
                                        <TransferDetailPageLine
                                            label={translator('transferDetail.card.transferCode')}
                                            value={this.state.transaction.transfercode}
                                        />
                                    }
                                </>
                                : ""
                            }
                        </Paper>
                    </div>
                </div>
            </div>
        )
    }
}

class TransferDetailPageLine extends React.Component {
    render() {
        return (
            <div className='card-info'>
                <div className='card-label'>{this.props.label}</div>
                <div className='card-value' style={this.props.style || null} >{this.props.value}</div>
            </div>
        )
    }
}
export default withParams(withCheckKYC(withTokenSupport(withTranslator(TransferDetailPage))));
