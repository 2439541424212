import React from 'react';
import {Drawer, Fab} from '@material-ui/core';
import WithTranslator from '../../../providers/WithTranslator';

import './Header.scss';
import {ReactComponent as IconCancel} from "../../../resources/icons/icon-close.svg";
import {ReactComponent as Menu} from "../../../resources/icons/icon-menu.svg";
import LoginControl from "./LoginControl";

class HeaderMobileMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mobileMenuAnchor: null
        }
    }

    onClose = () => this.setState({mobileMenuAnchor: null})

    render() {
        const {translator} = this.props,
            open = !!this.state.mobileMenuAnchor;

        return (
            <>
                <div className='header-short'>
                    <Fab
                        onClick={e => this.setState({mobileMenuAnchor: e.currentTarget})}
                        disableRipple>
                        <Menu/>
                    </Fab>
                    {/*<LanguageControl/>*/}
                    <LoginControl/>
                </div>
                <Drawer anchor='left' open={open} onClose={this.onClose} className='header-mobile-menu'>
                    <Fab onClick={this.onClose} className='close-btn' disableRipple>
                        <IconCancel/>
                    </Fab>
                    <div className='menu'>
                        <a className={this.props.mainPartVisible ? '_active' : ''} href={'/#transfer'}
                           onClick={this.onClose}>
                            {translator('header.link.main')}
                        </a>
                        <a className={this.props.advantagesPartVisible ? '_active' : ''} href={'/#advantages'}
                            onClick={this.onClose}>
                            {translator('header.link.advantagesPart')}
                        </a>
                        <a className={this.props.about1PartVisible ? '_active' : ''} href={'/#to_the_sender'}
                            onClick={this.onClose}>
                            {translator('header.link.about1')}
                        </a>
                        <a className={this.props.about2PartVisible ? '_active' : ''} href={'/#to_the_recipient'}
                            onClick={this.onClose}>
                            {translator('header.link.about2')}
                        </a>
                    </div>
                </Drawer>
            </>
        )
    }
}

export default WithTranslator(HeaderMobileMenu);
