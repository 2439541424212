import React from 'react';
import {AppBar} from '@material-ui/core';
import WithTranslator from '../../../providers/WithTranslator';

import './Header.scss';
import {ReactComponent as Logo1} from "../../../resources/logo.svg";
import LoginControl from "./LoginControl";

class HeaderAppBar extends React.Component {
    render() {
        const {translator} = this.props;
        return (
            <>
                <AppBar position='fixed' className={`appbar`} id='header'>
                    <a href={'/#mainPart'}>
                        <div className='logo-container'>
                            <Logo1/>
                            WEBTRANSPAY
                        </div>
                    </a>

                    <div className='menu'>
                        <a className={this.props.mainPartVisible ? '_active' : ''} href={'/#transfer'}>
                            {translator('header.link.main')}
                        </a>
                        <a className={this.props.advantagesPartVisible ? '_active' : ''} href={'/#advantages'}>
                            {translator('header.link.advantagesPart')}
                        </a>
                        <a className={this.props.about1PartVisible ? '_active' : ''} href={'/#to_the_sender'}>
                            {translator('header.link.about1')}
                        </a>
                        <a className={this.props.about2PartVisible ? '_active' : ''} href={'/#to_the_recipient'}>
                            {translator('header.link.about2')}
                        </a>
                    </div>

                    <LoginControl/>
                </AppBar>
            </>
        )
    }
}

export default WithTranslator(HeaderAppBar);
