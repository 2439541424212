import React from 'react';
import WithTranslator from "../../../providers/WithTranslator";

import "./BadUserPage.scss";

class BadUserPage extends React.Component {
    render() {
        const translator = this.props.translator;
        return (
            <div className='bad-user'>
                <span >{translator('badUser.hello')}</span>
                <br />
                <br />
                <span>{translator('badUser.message')}</span>
                <br />
                <br />
                <span>{translator('badUser.wishes')}</span>
                <br />
                <span>{translator('badUser.signature')}</span>
            </div>
        )
    }
}

export default WithTranslator(BadUserPage);
