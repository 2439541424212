export default {
    'header.link.main': 'Перевод',
    'header.link.advantagesPart': 'Преимущества',
    'header.link.about1': 'Отправителю',
    'header.link.about2': 'Получателю',
    'header.link.commission': 'Условия',
    'header.lang.ru': 'RU',
    'header.lang.en': 'ENG',
    'header.btn.logIn': 'Авторизация',
    'header.btn.signUp': 'Регистрация',
    'header.btn.logOut': 'Выйти',
    'header.btn.account': 'Аккаунт',

    'mainPart.title': 'Переводы денег<br/>с карты на карту',
    'mainPart.btn.makeTransfer': 'Отправить перевод',
    'mainPart.btn.receiveTransfer': 'Получить перевод',

    'aboutPart.title1': 'Как провести перевод',
    'aboutPart.title2': 'Я получатель, как мне получить деньги?',
    'aboutPart.card1.title': 'Если я знаю карту получателя',
    'aboutPart.card2.title': 'Если я не знаю карту получателя',
    'aboutPart.card3.title': 'Если отправитель ввел данные моей карты',
    'aboutPart.card4.title': 'Если отправитель выслал мне код перевода',
    'aboutPart.card1.text': 'Ввести сумму перевода <br/> Ввести данные своей карты <br/> Согласится с условиями оферты <br/> Нажать «Продолжить» <br/> Ввести номер и дату окончания действия карты получателя денег <br/> Готово!',
    'aboutPart.card2.text': 'Ввести сумму перевода <br/> Ввести данные своей карты <br/> Согласится с условиями оферты <br/> Нажать «Продолжить» <br/> Получить код перевода <br/> Скопировать код перевода и отправить получателю <br/> Готово!',
    'aboutPart.card3.text': 'Нужно лишь подождать пока пройдет перевод! <br/> Срок зачисления средств на счет карты получателя зависит от банка, выпустившего карту получателя, и может составлять от нескольких минут до нескольких дней',
    'aboutPart.card4.text': 'Перейти на вкладку получить перевод <br/> Вставить код перевода <br/> Нажать «Получить» <br/> Готово!',

    'transferPart.title': 'Перевод',
    'transferPart.form.card1.title': 'Карта отправителя',
    'transferPart.form.card2.title': 'Карта получателя',
    'transferPart.form.amount.title': 'Сумма',
    'transferPart.menu.btn.makeTransfer': 'Сделать перевод',
    'transferPart.menu.btn.receiveTransfer': 'Получить перевод',
    'transferPart.form.currency.usd': '$ Доллар',
    'transferPart.form.currency.eur': '€ Евро',
    'transferPart.form.amount.placeholder': 'Сумма перевода',
    'transferPart.form.currency.placeholder': 'Валюта',
    'transferPart.form.card1.placeholder': 'Номер карты отправителя',
    'transferPart.form.card2.placeholder': 'Номер карты получателя',
    'transferPart.form.owner.placeholder': 'Держатель карты',
    'transferPart.form.date.placeholder': 'Месяц/Год',
    'transferPart.form.cvv.placeholder': 'CVV',
    'transferPart.form.commission1': 'Сумма перевода ',
    'transferPart.form.commission2': 'Комиссия составит ',
    'transferPart.form.commission3': 'Итого ',
    'transferPart.form.agree1': 'Я согласен с условиями',
    'transferPart.form.agree2': 'оферты',
    'transferPart.form.code': 'Получить код перевода',
    'transferPart.form.code.description': 'Что это?',
    'transferPart.form.code.tooltip': 'Если вы не знаете данные карты получателя, вы можете получить код перевода. После отправления кода получателю, он введет карту, на которую получит деньги самостоятельно',
    'transferPart.form.btn1.submit': 'Продолжить',
    'transferPart.form.btn1.cancel': 'Сбросить',
    'transferPart.form.btn2.submit': 'Перевести',
    'transferPart.form.btn2.cancel': 'Назад',
    'transferPart.form.get.submit': 'Получить',
    'transferPart.result.wait.title': 'Проверка транзакции',
    'transferPart.result.wait.description': 'Обычно это занимает несколько секунд, не закрывайте и не обновляйте эту страницу',
    'transferPart.result.code.title': 'Код перевода',
    'transferPart.result.code.description': 'Скопируйте код и отправьте его получателю',
    'transferPart.result.code.submit': 'Новый перевод',
    'transferPart.result.success.title': 'Ваши средства в пути',
    'transferPart.result.success.description': 'Обработка запроса прошла успешно',
    'transferPart.result.success.submit': 'Новый перевод',
    'transferPart.result.warning.title': 'Где-то ошибка',
    'transferPart.result.warning.description': 'Запрос не прошел, но можно попробовать еще раз',
    'transferPart.result.warning.submit': 'Попробовать еще раз',
    'transferPart.result.limit.title': 'Превышен лимит',
    'transferPart.result.limit.description': 'Сумма переводов достигла лимита. Чтобы увеличить лимит, свяжитесь с технической поддержкой.',
    'transferPart.result.limit.submit': 'OK',

    'loginUserPart.link.forgotPassword': 'Забыли пароль?',
    'loginUserPart.link.dontHaveAccount': 'Нет аккаунта Webtranspay?',
    'loginUserPart.link.haveAccount': 'Уже есть аккаунт Webtranspay?',
    'loginUserPart.form.email': 'Email',
    'loginUserPart.form.password': 'Пароль',
    'loginUserPart.form.firstName': 'Имя',
    'loginUserPart.form.lastName': 'Фамилия',
    'loginUserPart.form.code': 'Код',
    'loginUserPart.form.key': 'Ключ',
    'loginUserPart.form.tfa': 'Текстовый код',
    'loginUserPart.form.btn.info': 'OK',
    'loginUserPart.loginUser.title': 'Авторизация',
    'loginUserPart.loginUser.btn.submit': 'Войти',
    'loginUserPart.recoverPassword.title': 'Восстановление пароля',
    'loginUserPart.recoverPassword.btn.submit': 'Сбросить пароль',
    'loginUserPart.registerUser.title': 'Регистрация',
    'loginUserPart.registerUser.step1.btn.submit': 'Зарегестрироваться',
    'loginUserPart.registerUser.step2.btn.submit': 'Подтвердить',
    'loginUserPart.registerUser.waitEmail.title': 'Ждите email',
    'loginUserPart.registerUser.waitEmail.info': 'На ваш email было отправлено письмо с сылкой для подтверждения.',
    'loginUserPart.registerUser.registered.title': 'Вы зарегестрированы',
    'loginUserPart.registerUser.registered.info': 'Аккаунт в webtranspay успешно создан.',
    'loginUserPart.forcedTfa.title': 'Подключение TFA',
    'loginUserPart.forcedTfa.btn.submit': 'Подключить',
    'loginUserPart.forcedTfa.info.QR': 'Тут информаця по tfa. Скорее всего длинная информация длинная информация длинная информация длиннная информация',
    'loginUserPart.forcedTfa.info.Key': 'Тут информаця по tfa. Скорее всего длинная информация длинная информация длинная информация длиннная информация',
    'loginUserPart.forcedTfa.info.Code': 'Тут информаця по tfa. Скорее всего длинная информация длинная информация длинная информация длиннная информация',
    'loginUserPart.needTfa.title': 'Двухфакторная аутентификация',
    'loginUserPart.needTfa.btn.submit': 'Подтвердить',

    'commissionPart.title': 'Условия',
    'commissionPart.commission': 'Комиссия:',
    'commissionPart.limit': 'Лимит:',
    'commissionPart.title11': 'Внутренние переводы',
    'commissionPart.title12': 'Между картами Российских банков',
    'commissionPart.title21': 'Трансграничные переводы',
    'commissionPart.title22': 'с карты РФ на иностранные',
    'commissionPart.commission1': '1,5%',
    'commissionPart.commission2': '2%',
    'commissionPart.limit1': 'Минимум 50 ₽',
    'commissionPart.limit2': 'Минимум 200 ₽',
    'commissionPart.warning.title': 'Сроки',
    'commissionPart.warning': 'Срок зачисления средств на счет карты получателя зависит от банка, выпустившего карту получателя, и может составлять от нескольких минут до нескольких дней',

    'advantagesPart.title': 'Преимущества',
    'advantagesPart.title0': 'Без карты получателя',
    'advantagesPart.text0': 'Можно перевести деньги,<br/>не зная данных карты получателя',
    'advantagesPart.title1': 'Другие банки',
    'advantagesPart.text1': 'Пополнение средств<br/>платёжной карты другого банка',
    'advantagesPart.title2': 'За границу',
    'advantagesPart.text2': 'Можно отправлять деньги<br/>на карты за границу',

    'contactsPart.form.title': 'Техническая поддержка',
    'contactsPart.form.email': 'Email',
    'contactsPart.form.name': 'Имя',
    'contactsPart.form.name.placeholder': 'Иван',
    'contactsPart.form.transaction': 'Номер перевода',
    'contactsPart.form.message': 'Ваше сообщение',
    'contactsPart.form.message.placeholder': 'Сообщение',
    'contactsPart.form.btn.submit': 'Отправить',
    'contactsPart.wait.title': 'Отзыв отправляется',
    'contactsPart.success.title': 'Отзыв отправлен',
    'contactsPart.warning.title': 'Что-то пошло не так',
    'contactsPart.warning.btn': 'Попробовать еще раз',
    'contactsPart.email.subject': trId => `Обратная связь (${trId})`,
    'contactsPart.email.message': (name, email, trId, message) => `Имя пользователя:\n${name}\n\nПочта пользователя:\n${email}\n\nТранзакиця:\n${trId}\n\nСообщение:\n${message}`,

    'transferDetail.title': 'Транзакция',
    'transferDetail.card.transactionId': 'ID транзакции',
    'transferDetail.card.created': 'Дата создания',
    'transferDetail.card.transferAmount': 'Сумма трансфера',
    'transferDetail.card.fee': 'Комиссия',
    'transferDetail.card.totalAmount': 'Общая сумма',
    'transferDetail.card.currency': 'Валюта',
    'transferDetail.card.status': 'Статус',
    'transferDetail.card.cardNumber': 'Карта',
    'transferDetail.card.cardholder': 'Держатель карты',
    'transferDetail.card.transferCode': 'Код трансфера',

    'profile.title': 'Профиль',
    'profile.personalInfo.title': 'Персональная информация',
    'profile.personalInfo.firstName.title': 'Имя',
    'profile.personalInfo.lastName.title': 'Фамилия',
    'profile.personalInfo.email.title': 'E-mail',
    'profile.tfa.title': 'Персональная информация',
    'profile.tfa.newDevice.title': 'Добавить новое устройство',
    'profile.tfa.newSecret.title': 'Сгенерировать новый код',
    'profile.tfa.add.btn': 'Добавить +',
    'profile.kyc.title': 'KYC',
    'profile.kyc.status.title': 'Статус ',
    'profile.changePassword.btn': 'Сменить пароль',

    'footer.title1': 'Asia TransPay (HK) Limited<br/>Company Number: 2676084',
    'footer.text1': 'Unit 2, 22/F. Richmond Commercial Building,<br/>109 Argyle Street, Mongkok, Kowloon, Hong Kong',
    'footer.title2': 'Техническая поддержка',
    'footer.text2': 'Напишите нам, если у вас есть вопросы, проблемы или хотите выразить благодарность',
    'footer.agreements': 'Соглашения',
    'footer.agreements.offer': 'Оферта',
    'footer.agreements.terms': `Условияи&#160;спользования`,
    'footer.agreements.terms.service': `Условия&#160;обслуживания`,
    'footer.agreements.policy': 'Политика&#160;конфиденциальности',
    'footer.btn.feedback': 'Написать',
    'footer.btn.address': 'Карта',

    'textInput.copy': 'Скопировать',
    'textInput.insert': 'Вставить',

    'backLink.back': 'Назад',
    'backLink.toMain': 'На главную',
}
