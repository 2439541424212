import React from 'react';
import { Outlet } from "react-router-dom";
import withTranslator from "../../providers/WithTranslator";
import WithCurrentPage, {pages} from "../../providers/WithCurrentPage";
import {withValidateLogin} from "../../providers/WithValidateLogin";
import Menu from "./menu/Menu";
import ContactsPart from "./contactsPart/ContactsPart";
import AccountFooter from "./footer/AccountFooter";
import AccountHeaderDesktop from "./header/AccountHeaderDesktop";
import AccountHeaderMobileMenu from "./header/AccountHeaderMobileMenu";
import {ACCOUNT_PAGES} from "../../constants";


class AccountLayout extends React.Component {
    constructor(props) {
        super(props);
        this.currAccountPage = this.getCurrAccountPage();
        this.state = {
            feedback: false,
        }
    }

    getCurrAccountPage = () => {
        const path = window.location.pathname;
        const splitPath = path.split('/');
        const length = splitPath.length;
        const result = splitPath[length-1] || splitPath[length-2];
        return ACCOUNT_PAGES[result] || ACCOUNT_PAGES.transfers
    }

    componentDidMount() {
        this.props.setCurrentPage(pages.Account)
    }

    openFeedback = () => this.setState({feedback: true});

    closeFeedback = () => this.setState({feedback: false});

    render() {
        return (
            <div className='app-container'>
                <AccountHeaderDesktop />
                <AccountHeaderMobileMenu currAccountPage={this.currAccountPage} />
                <Menu currAccountPage={this.currAccountPage} />
                <Outlet />
                <ContactsPart open={this.state.feedback} closeFeedback={this.closeFeedback}/>
                <AccountFooter openFeedback={this.openFeedback}/>
            </div>
        )
    }
}

export default withValidateLogin(WithCurrentPage(withTranslator(AccountLayout)));
