import React from 'react';
import {Link} from "react-router-dom";
import {Drawer, Fab} from '@material-ui/core';
import WithTranslator from '../../../providers/WithTranslator';
import WithTokenSupport from "../../../providers/WithTokenSupport";
import WithCurrentPage from "../../../providers/WithCurrentPage";
import {ACCOUNT_PAGES} from "../../../constants";

import {ReactComponent as IconCancel} from "../../../resources/icons/icon-close.svg";
import {ReactComponent as Menu} from "../../../resources/icons/icon-menu.svg";
import {ReactComponent as Logo1} from "../../../resources/logo.svg";
import {ReactComponent as MainPageIcon} from "../../../resources/icons/main-page.svg";
import {ReactComponent as SignOutIcon} from "../../../resources/icons/sign-out.svg";
import {ReactComponent as TransfersIcon} from "../../../resources/icons/menu-transfers.svg";
import {ReactComponent as SendIcon} from "../../../resources/icons/menu-send.svg";
import {ReactComponent as ReceiveIcon} from "../../../resources/icons/menu-receive.svg";
import {ReactComponent as ProfileIcon} from "../../../resources/icons/menu-profile.svg";
import './Header.scss';


class AccountHeaderMobileMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mobileMenuAnchor: null,
            currPage: props.currAccountPage,
        }
    }

    onClose = () => this.setState({mobileMenuAnchor: null})

    onLinkClick = (page) => {
        this.setState({currPage: page, mobileMenuAnchor: false});
    }

    render() {
        const {translator} = this.props,
            open = !!this.state.mobileMenuAnchor;

        return (
            <>
                <div className="header-short account">
                    <div className="top-line">
                        <Link to={'/'} >
                            <div className='account-logo'>
                                <Logo1/>
                                WEBTRANSPAY
                            </div>
                        </Link>
                        <Fab
                            onClick={e => this.setState({mobileMenuAnchor: e.currentTarget})}
                            disableRipple>
                            <Menu/>
                        </Fab>
                    </div>
                    <div className="bottom-line">
                        <Link to={'/'} className="link">
                            <MainPageIcon/>&nbsp;
                            <span>{translator("account.header.mainPage")}</span>
                        </Link>

                        <Link to={'/'} className="link" onClick={() => {this.props.removeTokens()}}>
                            <SignOutIcon/>&nbsp;
                            <span>{translator("account.header.signOut")}</span>
                        </Link>
                    </div>

                </div>
                <Drawer anchor='right' open={open} onClose={this.onClose} className='header-mobile-menu account'>
                    <Fab onClick={this.onClose} className='close-btn' disableRipple>
                        <IconCancel/>
                    </Fab>
                    <div className="account-link-container">
                        <Link to="/account/transfers"
                              className={`link${this.state.currPage === ACCOUNT_PAGES.transfers ? " currPage" : ""}`}
                              onClick={() => this.onLinkClick(ACCOUNT_PAGES.transfers)}
                        >
                            <TransfersIcon /> &nbsp;
                            <span>{translator("account.menu.transfers")}</span>
                        </Link>
                        <Link
                            to="/account/send"
                            className={`link${this.state.currPage === ACCOUNT_PAGES.send ? " currPage" : ""}`}
                            onClick={() => this.onLinkClick(ACCOUNT_PAGES.send)}
                        >
                            <SendIcon /> &nbsp;
                            <span>{translator("account.menu.send")}</span>
                        </Link>
                        <Link
                            to="/account/receive"
                            className={`link${this.state.currPage === ACCOUNT_PAGES.receive ? " currPage" : ""}`}
                            onClick={() => this.onLinkClick(ACCOUNT_PAGES.receive)}
                        >
                            <ReceiveIcon /> &nbsp;
                            <span>{translator("account.menu.receive")}</span>
                        </Link>
                        <Link
                            to="/account/profile"
                            className={`link${this.state.currPage === ACCOUNT_PAGES.profile ? " currPage" : ""}`}
                            onClick={() =>  this.onLinkClick(ACCOUNT_PAGES.profile)}>
                            <ProfileIcon /> &nbsp;
                            <span>{translator("account.menu.profile")}</span>
                        </Link>
                    </div>
                </Drawer>
            </>
        )
    }
}

export default WithCurrentPage(WithTokenSupport(WithTranslator(AccountHeaderMobileMenu)));
