import React from 'react';
import {Link} from "react-router-dom";
import {Paper} from '@material-ui/core';
import apiService from "../../../../services/apiService";
import withCheckKYC from "../../../../providers/WithCheckKYC";
import withTranslator from "../../../../providers/WithTranslator";
import ShowTFA from "../../../tfa/ShowTFA";
import {ReactComponent as IconBack} from "../../../../resources/icons/icon-back.svg";


class ShowTFAPage extends React.Component {

    render() {
        const translator = this.props.translator;
        return (
            <div className="transfer-part account-part">
                <span className='header'>
                    {translator('showTFA.info.title')}
                </span>
                <Link to={'/account/profile'} className='back-link'>
                    <IconBack/>
                    <span>{translator('backLink.back')}</span>
                </Link>
                <div className='transfer-part__inner'>
                    <div className='cards centralize'>
                        <Paper className='card'>
                            <ShowTFA getTFARequest={apiService.getCurrentTfa}/>
                        </Paper>
                    </div>
                </div>
            </div>
        )
    }
}

export default withCheckKYC(withTranslator(ShowTFAPage));
