import React from 'react';
import {Link} from "react-router-dom";
import {Button} from '@material-ui/core';
import TextInput from '../textInpit/TextInput';
import WithTranslator from '../../providers/WithTranslator';
import {RESULT_STATUS as STATUS} from '../../constants';

import {ReactComponent as Success} from '../../resources/success.svg';
import {ReactComponent as Warning} from '../../resources/warning.svg';
import Loader from "../loader/Loader";
import {ReactComponent as IconBack} from "../../resources/icons/icon-back.svg";

class TransferResultStep extends React.Component {
    render() {
        const {translator, status} = this.props;
        return (
            <>
                {/* ToDo временное решение co style, после реализации задачи по состоянию приложения - изменить */}
                {status !== STATUS.WAIT &&
                <Link to={'/'} className='back-link'
                      style = {{ display: window.location.pathname.includes('account') ? "none" : "" }}
                >
                    <IconBack/>
                    <span>{translator('backLink.toMain')}</span>
                </Link>
                }
                <div className='finish-step' id='transferResult'>
                    <span className='header'>{translator(`transferPart.result.${status}.title`)}</span>
                    {status === STATUS.WAIT && <Loader/>}
                    {status === STATUS.SUCCESS && <Success/>}
                    {(status === STATUS.ERROR || status === STATUS.LIMIT) && <Warning/>}
                    {status === STATUS.CODE &&
                        <>
                            <span className='code'>
                                <TextInput value={this.props.trId} disabled withCopy
                                           label={translator(`transferPart.result.${status}.title`)}/>
                                {/*<TransferReport {...this.props}/>*/}
                            </span>
                        </>
                    }

                    {status !== STATUS.CODE && translator(`transferPart.result.${status}.description`)}

                    {status !== STATUS.WAIT &&
                    <Button className={`primary submit`} onClick={this.props.onSubmit}>
                        {translator(`transferPart.result.${status}.submit`)}
                    </Button>
                    }
                </div>
            </>
        )
    }

}

export default WithTranslator(TransferResultStep);
