import React from 'react';
import FieldsHelper from "../components/common/FieldsHelper";
import apiService from "../services/apiService";
import SumsubWebSdk from "@sumsub/websdk-react";
import {REVIEW_ANSWER} from "../constants";
import BadUserPage from "../components/pages/badUser/BadUserPage";
import withCurrentPage, {pages} from "./WithCurrentPage"
import withTokenSupport from "./WithTokenSupport";
import WithTranslator from "./WithTranslator";
import {Link} from "react-router-dom";
import {ReactComponent as IconBack} from "../resources/icons/icon-back.svg";


export default function (WrappedComponent) {

    let _class = class extends React.Component {
        constructor(props) {
            super(props);

            this.FHelper = new FieldsHelper(this);
            this.state = {
                errors: {},
                token: '',
                kyc_passed: false,
                bad_User: true
            };
        }

        getTokenResponse = () => {
            return this.props.sendRequest(apiService.getKYCToken).then(response => {
                const tokenResponse = {
                    token: '',
                    errors: {}
                };
                if (!response?.errors) {
                    tokenResponse.token = response.token
                } else {
                    tokenResponse.errors = response.errors;
                }
                this.setState({...tokenResponse}, () => window.scrollTo({top: 0}))
                if (!response?.errors) {
                    return response.token
                } else {
                    return null
                }
            });
        }

        componentDidMount() {
            // ToDo Использовать userStatus
            // Если пользователь не залогинен, то мы не должны проверять KYC на странице MainTransferReceive
            // Для страниц в лк успевает сработать WithValidateLogin и до сюда не доходит.
            let isLogged =  localStorage.getItem("refresh");
            if (!isLogged) {
                return
            }
            this.props.sendRequest(apiService.checkApplicant).then(response => {
                if (!response?.errors){
                    const kyc_started = response?.kyc_started;
                    const reviewStatus = response?.reviewStatus;
                    const reviewResult = response?.reviewResult;
                    //const description = response?.description;
                    // ToDo рассмотреть вариант без использования дополнительной переменной
                    const new_state = {}
                    if (!kyc_started || (kyc_started && (reviewStatus === "init" || reviewStatus === "pending"))) {
                        this.getTokenResponse();
                        Object.assign(new_state, {...response, kyc_passed: false});
                    } else {
                        Object.assign(new_state, {...this.state, kyc_passed: true});
                        if (reviewResult && reviewResult?.reviewAnswer === REVIEW_ANSWER.GREEN) {
                            Object.assign(new_state, {...new_state, bad_User: false})
                        } else {
                            Object.assign(new_state, {...new_state, bad_User: true})
                        }
                    }
                    this.setState({...new_state}, () => window.scrollTo({top: 0}))
                }
            })
        }

        onMessage(type, payload) {
            if (type === 'idCheck.onResize') {
                window.scrollTo(0,0);
            }
        }

        onError(error) {
            console.log(error);
        }

        render() {
            // ToDo Использовать userStatus
            let isLogged =  localStorage.getItem("refresh");
            let currentPage = this.props.currentPage
            const translator = this.props.translator;

            if (this.props.currentPage !== pages.Account && !isLogged) {
                return (
                    <WrappedComponent
                        {...this.props}
                    />
                )
            }

            return (
                <>
                    {!this.state.kyc_passed &&
                        <div className={`transfer-part ${currentPage === pages.Account ? "account-part" : ""}`}>
                            {/* ToDo временное решение co style, после реализации задачи по состоянию приложения - изменить */}
                            <Link to={'/'} className='back-link'
                                  style = {{ display: window.location.pathname.includes('account') ? "none" : "" }}
                            >
                                <IconBack/>
                                <span>{translator('backLink.toMain')}</span>
                            </Link>
                            {this.state.token !== '' &&
                                <SumsubWebSdk
                                    accessToken={this.state.token}
                                    expirationHandler={this.getTokenResponse}
                                    className="sumsub-container"
                                    config={{
                                        lang: 'en',
                                        phone: '',
                                        i18n: {"document": {"subTitles": {"IDENTITY": "Upload a document that proves your identity"}}},
                                        uiConf: {
                                            customCssStr: `:root {
                                              --black: #000000;
                                              --grey: #F5F5F5;
                                              --grey-darker: #B2B2B2;
                                              --border-color: #DBDBDB;
                                            }
                                            
                                            p {
                                              color: var(--black);
                                              font-size: 16px;
                                              line-height: 24px;
                                            }
                                            
                                            section {
                                              margin: 40px auto;
                                            }
                                            
                                            input {
                                              color: var(--black);
                                              font-weight: 600;
                                              outline: none;
                                            }
                                            
                                            section.content {
                                              background-color: var(--grey);
                                              color: var(--black);
                                              padding: 40px 40px 16px;
                                              box-shadow: none;
                                              border-radius: 6px;
                                            }
                                            
                                            button.submit,
                                            button.back {
                                              text-transform: capitalize;
                                              border-radius: 6px;
                                              height: 48px;
                                              padding: 0 30px;
                                              font-size: 16px;
                                              background-image: none !important;
                                              transform: none !important;
                                              box-shadow: none !important;
                                              transition: all 0.2s linear;
                                            }
                                            
                                            button.submit {
                                              min-width: 132px;
                                              background: none;
                                              background-color: var(--black);
                                            }
                                            
                                            .round-icon {
                                              background-color: var(--black) !important;
                                              background-image: none !important;
                                            }
                                            
                                            .iframe2.min-height {
                                              min-height: 0;
                                            }`
                                        },
                                    }}
                                    options={{addViewportTag: false, adaptIframeHeight: true}}
                                    onMessage={this.onMessage}
                                    onError={this.onError}
                                />
                            }
                        </div>
                    }
                    {this.state.kyc_passed &&
                    <>
                        {this.state.bad_User
                            ?
                            <div className={`transfer-part ${currentPage === pages.Account ? "account-part" : ""}`}>
                                <div className="transfer-part__inner">
                                    {/* ToDo временное решение co style, после реализации задачи по состоянию приложения - изменить */}
                                    <Link to={'/'} className='back-link'
                                          style = {{ display: window.location.pathname.includes('account') ? "none" : "" }}
                                    >
                                        <IconBack/>
                                        <span>{translator('backLink.toMain')}</span>
                                    </Link>
                                    <BadUserPage/>
                                </div>
                            </div>
                            :
                            <WrappedComponent
                                {...this.props}
                            />
                        }
                    </>
                    }
                </>
            )
        }
    };
    return withTokenSupport(withCurrentPage(WithTranslator(_class)));
}
