import React from 'react';
import {Paper} from "@material-ui/core";

import './Loader.scss';

export default function () {
    return (
        <Paper className='loader'>
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path d="M26.1365 0C24.8813 0 23.8638 1.01758 23.8638 2.27275V11.3637C23.8638 12.6188 24.8813 13.6364 26.1365 13.6364C27.3917 13.6364 28.4093 12.6188 28.4093 11.3637V2.27275C28.4093 1.01758 27.3917 0 26.1365 0Z"/>
                    <path d="M18.1015 13.7507L11.6732 7.32219C10.7859 6.43459 9.34685 6.43459 8.45916 7.32219C7.57156 8.20979 7.57156 9.64875 8.45916 10.5363L14.8875 16.9648C15.3312 17.4085 15.913 17.6305 16.4944 17.6305C17.076 17.6305 17.6578 17.4085 18.1013 16.9648C18.9891 16.0772 18.9891 14.6383 18.1015 13.7507Z"/>
                    <path d="M14.7722 24.9999C14.7722 23.7447 13.7546 22.7272 12.4994 22.7272H3.4085C2.15332 22.7272 1.13574 23.7447 1.13574 24.9999C1.13574 26.2551 2.15332 27.2727 3.4085 27.2727H12.4994C13.7546 27.2727 14.7722 26.2551 14.7722 24.9999Z"/>
                    <path d="M37.386 33.0354C36.4986 32.1478 35.0596 32.148 34.172 33.0354C33.2844 33.923 33.2846 35.3618 34.172 36.2494L40.6005 42.6777C41.0442 43.1214 41.626 43.3433 42.2076 43.3433C42.7893 43.3433 43.371 43.1213 43.8146 42.6777C44.7022 41.7901 44.7022 40.3511 43.8146 39.4636L37.386 33.0354Z"/>
                    <path d="M26.1365 36.3636C24.8813 36.3636 23.8638 37.3812 23.8638 38.6364V47.7273C23.8638 48.9825 24.8813 50.0001 26.1365 50.0001C27.3917 50.0001 28.4093 48.9825 28.4093 47.7273V38.6364C28.4093 37.3812 27.3917 36.3636 26.1365 36.3636Z"/>
                    <path d="M18.1009 33.0354C17.2135 32.1479 15.7744 32.1479 14.8869 33.0354L8.45867 39.4637C7.57107 40.3511 7.57107 41.7902 8.45867 42.6777C8.90242 43.1215 9.48415 43.3434 10.0658 43.3434C10.6474 43.3434 11.2292 43.1216 11.6727 42.6777L18.1009 36.2495C18.9885 35.3621 18.9885 33.923 18.1009 33.0354Z"/>
                    <path d="M14.7722 24.9999C14.7722 23.7447 13.7546 22.7272 12.4994 22.7272H3.4085C2.15332 22.7272 1.13574 23.7447 1.13574 24.9999C1.13574 26.2551 2.15332 27.2727 3.4085 27.2727H12.4994C13.7546 27.2727 14.7722 26.2551 14.7722 24.9999Z"/>
                    <path d="M18.1015 13.7507L11.6732 7.32219C10.7859 6.43459 9.34685 6.43459 8.45916 7.32219C7.57156 8.20979 7.57156 9.64875 8.45916 10.5363L14.8875 16.9648C15.3312 17.4085 15.913 17.6305 16.4944 17.6305C17.076 17.6305 17.6578 17.4085 18.1013 16.9648C18.9891 16.0772 18.9891 14.6383 18.1015 13.7507Z"/>
                </g>
            </svg>
        </Paper>
    );
}
