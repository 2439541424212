import React from 'react';
import {BehaviorSubject} from 'rxjs';
import apiService from "../services/apiService";
import {UNAUTHORIZED, AUTHORIZED} from "../constants";

const getUserStatus = () => localStorage.getItem('refresh') ? AUTHORIZED : UNAUTHORIZED;

const userStatus$ = new BehaviorSubject(getUserStatus());

const setUserStatus = status => {
    userStatus$.next(status);
};

const setTokens = (data) => {
    if (data?.access) localStorage.setItem('access', data.access);
    if (data?.refresh) {
        localStorage.setItem('refresh', data.refresh);
        setUserStatus(AUTHORIZED);
    }
}

const removeTokens = () => {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    setUserStatus(UNAUTHORIZED);
}

const sendRequest = (requestType, data={}) =>
    requestType(data).then((response) => {
        return response.data;
    })
    .catch((errorResponse) => {
        if (errorResponse.status === 401) {
            return apiService.refreshToken().then((response) => {
                if (response.data?.access) {
                    localStorage.setItem('access', response.data.access);
                    return sendRequest(requestType, data);
                }
            }).catch((error) => {
                if (error.status === 401) {
                    removeTokens();
                    window.location.reload();
                }
                return {errors: error}
            })
        } else {
            return {errors: errorResponse}
        }
    })

export default function (WrappedComponent) {
    return class extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                status: getUserStatus(),
            };
        }

        componentDidMount() {
            this.subscription = userStatus$.subscribe(status =>
                this.setState({status})
            );
        }

        componentWillUnmount() {
            this.subscription.unsubscribe();
        }

        render() {
            return (
                <>
                    <WrappedComponent
                        {...this.props}
                        userStatus={this.state.status}
                        setUserStatus={setUserStatus}
                        sendRequest={sendRequest}
                        setTokens={setTokens}
                        removeTokens={removeTokens}
                    />
                </>
            );
        }
    };
}
