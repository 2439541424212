import React from 'react';
import {Button, Paper} from '@material-ui/core';
import TextInput from '../textInpit/TextInput';
import apiService from '../../services/apiService';
import FieldsHelper from '../common/FieldsHelper';
import WithTranslator from '../../providers/WithTranslator';
import WithTokenSupport from "../../providers/WithTokenSupport";
import WithCurrentPage, {pages} from "../../providers/WithCurrentPage";
import ShowTFA from "./ShowTFA";


const defaultFields = {
    key: '',
};

class EnableTFA extends React.Component {
    constructor(props) {
        super(props);

        this.FHelper = new FieldsHelper(this);
        this.state = {
            fields: defaultFields,
            errors: {},
        };
    }

    onSubmit = () => {
        const errors = {
            key: !this.state.fields.key,
        };
        if (Object.values(errors).some(i => !!i)) {
            this.setState({errors: {...errors}});
        } else {
            this.send();
        }
    }

    isSubmitDisabled = () => {
        const isEmpty = !Object.entries(this.state.fields).every(([k, v]) => !!v)
        const isErrors = Object.values(this.state.errors).some(e => !!e)
        return isEmpty || isErrors;
    }

    send = () => {
        const data = {
            'key': this.state.fields.key,
            'use_tfa': "true",
        }
        this.props.sendRequest(apiService.enableTfa, data).then(response => {
            const new_state = this.props.onResponse(response);
            this.setState({...new_state}, () => window.scrollTo({top: 0}))
            if (!Object.keys(new_state.errors).length) {
                this.props.onSuccess(true);
            }
        });
    }


    render() {
        const translator = this.props.translator;
        return (
            <div className='transfer'>
                <div>
                    <span className='header'>
                        {translator('enableTFA.title')}
                    </span>
                    <div className='cards centralize'>
                        <Paper className='card'>
                            <ShowTFA getTFARequest={this.props.getTFARequest}/>
                            <p>{translator('enableTFA.info.Code')}</p>
                            <TextInput label={translator('loginUserPart.form.code')}
                                   withErrorText autoComplete='off'
                                   placeholder='111111'
                                   error={!!this.state.errors.key}
                                   errorText={this.state.errors.key}
                                   value={this.state.fields.key}
                                   onChange={this.FHelper.onKeyChange}/>
                        </Paper>
                    </div>
                </div>
                <div className='center'>
                    <Button className='submit primary' onClick={this.onSubmit}
                        disabled={this.isSubmitDisabled()}>
                        {translator( this.props.currentPage === pages.Other
                            ? 'enableTFA.btn.submit'
                            : 'enableTFA.btn.update')
                        }
                    </Button>
                </div>
            </div>
        )
    }
}

export default WithTokenSupport(WithCurrentPage(WithTranslator(EnableTFA)));
