import React from 'react';
import ReactHtmlParser from "react-html-parser";
import {Link, Navigate} from "react-router-dom";
import {Button, Paper} from '@material-ui/core';
import TextInput from '../../textInpit/TextInput';
import {ReactComponent as IconBack} from "../../../resources/icons/icon-back.svg";
import WithTokenSupport from "../../../providers/WithTokenSupport";
import WithTranslator from '../../../providers/WithTranslator';
import WithCurrentPage, {pages} from "../../../providers/WithCurrentPage";
import apiService from '../../../services/apiService';
import FieldsHelper from '../../common/FieldsHelper';
import FValidator from '../../common/FieldsValidator';
import {LOGIN_STEP as STEP, AUTHORIZING, AUTHORIZED, UNAUTHORIZED} from '../../../constants';
import EnableTFA from "../../tfa/EnableTFA";
import CheckTFACode from "./CheckTFACode";

import "./LoginUser.scss"

const defaultFields = {
    email: '',
    password: '',
};

class LoginUser extends React.Component {
    constructor(props) {
        super(props);

        this.FHelper = new FieldsHelper(this);
        this.state = {
            step: STEP.FIRST,
            fields: defaultFields,
            errors: {},
            success: false,
            authorized: props.userStatus === AUTHORIZED
        };
    }

    componentDidMount() {
        this.props.setCurrentPage(pages.Other)
        if (this.props.userStatus === UNAUTHORIZED){
            this.props.setUserStatus(AUTHORIZING);
        }
        document.addEventListener('keydown', this.customHandleKeyDown)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.customHandleKeyDown);
    }

    onSubmit = () => {
        const errors = {
            email: FValidator.isEmailValid(this.state.fields.email).detail,
            password: !this.state.fields.password,
        };
        if (Object.values(errors).some(i => !!i)) {
            this.setState({errors: {...errors}}, () => window.scrollTo({top: 0}));
        } else {
            this.send();
        }
    }

    customHandleKeyDown = (e) => {
        if (e.key !== 'Enter') return;
        let submitEl = document.querySelector('.submit');
        if (!submitEl.disabled){
            submitEl.click();
        }
    }

    isSubmitDisabled = () => {
        const isEmpty = !Object.entries(this.state.fields).every(([k, v]) => !!v)
        const isErrors = Object.values(this.state.errors).some(e => !!e)
        return isEmpty || isErrors;
    }

    send = () => {
        const data = {
            'email': this.state.fields.email,
            'password': this.state.fields.password,
        }
        apiService.loginUser(data).then(response => {
            const new_state = {success: false};
            if (response?.errors) {
                new_state.errors = response.errors;
            } else {
                new_state.errors = {};
                response?.need_tfa && (new_state.step = STEP.NEED_TFA);
                response?.forced_tfa && (new_state.step = STEP.FORCED_TFA);
                if (response?.refresh) {
                    new_state.success = true;
                }
                this.props.setTokens(response);
            }
            this.setState({...new_state}, () => window.scrollTo({top: 0}))
        });
    }

    // ToDo переделать: вынести в общую часть или переделать, используется для needTFA, forcedTFA, generateNewTFA в ЛК
    handleResponse = (response) => {
        let new_state = {};
        if (response?.errors) {
            new_state.errors = response?.errors.data;
        } else {
            new_state.errors = {};
            this.props.setTokens(response);
        }
        return new_state;
    }

    // ToDo название
    handleSuccess = (value) => {
        this.setState({success: value});
    }

    render() {
        const translator = this.props.translator;

        return (
            <div className='transfer-part' id='loginPart'>
                <div className='transfer-part__inner'>
                    <div className={`container`}>
                        <Link to={'/'} className='back-link'>
                            <IconBack/>
                            <span>{translator('backLink.toMain')}</span>
                        </Link>
                        {this.state.step === STEP.FIRST && (
                            <div className='transfer'>
                                <div>
                                    <span className='header'>
                                        {translator('loginUserPart.loginUser.title')}
                                    </span>

                                    <div className='cards'>
                                        <Paper className='card'>
                                            <TextInput label={translator('loginUserPart.form.email')}
                                                       withErrorText type="text"
                                                       className='margin-bottom'
                                                       placeholder='example@email.com'
                                                       value={this.state.fields.email}
                                                       error={!!this.state.errors.detail || !!this.state.errors.email}
                                                       errorText={this.state.errors.email}
                                                       onChange={this.FHelper.onEmailChange}
                                                       onBlur={this.FHelper.onEmailBlur}
                                            />
                                            <TextInput label={translator('loginUserPart.form.password')}
                                                       type='password' id="password"
                                                       placeholder='**********'
                                                       error={!!this.state.errors.detail}
                                                       value={this.state.fields.password}
                                                       onChange={this.FHelper.onPasswordChange}
                                            />
                                            <div className='margin-bottom'>
                                                <span className='error-text'>{this.state.errors.detail}</span>
                                            </div>
                                            <Link  to={'/register'}>
                                                {translator('loginUserPart.link.dontHaveAccount')}
                                            </Link><br/>
                                            <Link to={'/forgot-password'}>
                                                {translator('loginUserPart.link.forgotPassword')}
                                            </Link>
                                            <div className='margin-top center'>
                                                <a className='link' href={`/terms-webtranspay-${this.props.language}.pdf`} target='_blank'>
                                                    {ReactHtmlParser(translator('footer.agreements.terms'))}
                                                </a>
                                                <a className='link' href={`/policy-webtranspay-${this.props.language}.pdf`} target='_blank'>
                                                    {ReactHtmlParser(translator('footer.agreements.policy'))}
                                                </a>
                                            </div>
                                        </Paper>
                                    </div>
                                </div>
                                <div className='center'>
                                    <Button className='submit primary' onClick={this.onSubmit}
                                            disabled={this.isSubmitDisabled()}>
                                        {translator('loginUserPart.loginUser.btn.submit')}
                                    </Button>
                                </div>
                            </div>)}
                        {this.state.step === STEP.NEED_TFA &&
                            <CheckTFACode onResponse={this.handleResponse}
                                          onSuccess={this.handleSuccess}/>}
                        {this.state.step === STEP.FORCED_TFA
                            && <EnableTFA getTFARequest={apiService.getNewTfa}
                                          onResponse={this.handleResponse}
                                          onSuccess={this.handleSuccess}/>}
                        {this.state.success && <Navigate to={'/account/transfers'} replace/>}
                    </div>
                    {this.state.authorized && <Navigate to={'/account/transfers'} replace/>}
                </div>
            </div>
        )
    }
}

export default WithCurrentPage(WithTokenSupport(WithTranslator(LoginUser)));
