import React from "react";
import { Navigate } from "react-router-dom";

export const withValidateLogin = Component => props => {
    const isLogged = localStorage.getItem("refresh");

    if (!isLogged) {
        return <Navigate to={"/login"} replace/>;
    } else {
        return <Component {...props}/>;
    }
};
