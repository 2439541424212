import React from 'react';
import {Link, Navigate} from 'react-router-dom';
import {Button, Paper} from '@material-ui/core';
import TextInput from '../../../textInpit/TextInput';
import {ReactComponent as IconBack} from '../../../../resources/icons/icon-back.svg';
import WithTranslator from '../../../../providers/WithTranslator';
import WithTokenSupport from "../../../../providers/WithTokenSupport";
import WithCheckKYC from "../../../../providers/WithCheckKYC";
import apiService from '../../../../services/apiService';
import FieldsHelper from '../../../common/FieldsHelper';
import FValidator from '../../../common/FieldsValidator';


const defaultFields = {
    old_password: '',
    new_password: '',
    new_password_conf: '',
};

class ChangePasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.FHelper = new FieldsHelper(this);
        this.state = {
            fields: defaultFields,
            errors: {},
            success: false,
        };
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (e) => {
        if (e.key !== 'Enter') return;
        if (!this.isSubmitDisabled()){
            this.onSubmit();
        }
    }

    isSubmitDisabled = () => {
        const isEmpty = !Object.entries(this.state.fields).every(([k, v]) => !!v)
        const isErrors = Object.values(this.state.errors).some(e => !!e)
        return isEmpty || isErrors;
    }

    onSubmit = () => {
        const errors = {
            old_password: !this.state.fields.old_password,
            new_password: !FValidator.isPasswordValid(this.state.fields.new_password),
            new_password_conf: this.state.fields.new_password_conf !== this.state.fields.new_password,
        };
        if (Object.values(errors).some(i => !!i)) {
            this.setState((prevState) => ({ ...prevState, errors: {...errors} }));
        } else {
            this.send();
        }
    }

    send = () => {
        const data = {
            old_password: this.state.fields.old_password,
            new_password: this.state.fields.new_password,
            new_password_conf: this.state.fields.new_password_conf,
        }

        // TODO оптимизировать: обновить состояние без новой переменной + подобные места
        this.props.sendRequest(apiService.changePassword, data).then(state => {
            let new_state = {};
            if (state?.errors) {
                new_state.success = false;
                new_state.errors = state.errors.data;
            } else {
                new_state.success = true;
                new_state.errors = {};
            }
            this.setState((prevState) => ({ ...prevState, ...new_state}), () => window.scrollTo({top: 0}))
        });
    }

    render() {
        const translator = this.props.translator;
        return (
            <div className='transfer-part account-part' id='loginPart'>
                <div className='transfer-part__inner'>
                    <div className={`container`}>
                        <Link to={'/account/profile'} className='back-link'>
                            <IconBack/>
                            <span>{translator('backLink.back')}</span>
                        </Link>
                        <div className='transfer'>
                            <div>
                                <span className='header'>
                                    {translator('changePassword.title')}
                                </span>

                                <div className='cards'>
                                    <Paper className='card'>
                                        <TextInput label={translator('changePassword.field.oldPassword')}
                                                   type='password' autoComplete='new-password' withErrorText
                                                   className='margin-bottom' id='password'
                                                   placeholder='**********'
                                                   error={!!this.state.errors.old_password}
                                                   errorText={this.state.errors.old_password}
                                                   value={this.state.fields.old_password}
                                                   onChange={this.FHelper.onOldPasswordChange}/>
                                        <TextInput label={translator('changePassword.field.newPassword')}
                                                   type='password' autoComplete='new-password' withErrorText
                                                   className='margin-bottom' id='password'
                                                   placeholder='**********'
                                                   error={!!this.state.errors.new_password}
                                                   errorText={this.state.errors.new_password}
                                                   value={this.state.fields.new_password}
                                                   onChange={this.FHelper.onNewPasswordChange}
                                                   onBlur={this.FHelper.onNewPasswordBlur}/>
                                        <TextInput label={translator( 'changePassword.field.confirmNewPassword')}
                                                   type='password' autoComplete='new-password' withErrorText
                                                   className='margin-bottom' id='password_confirm'
                                                   placeholder='**********'
                                                   error={!!this.state.errors.new_password_conf}
                                                   errorText={this.state.errors.new_password_conf}
                                                   value={this.state.fields.new_password_conf}
                                                   onChange={this.FHelper.onNewPasswordConfirmChange}
                                                   onBlur={(e) => {this.FHelper.onNewPasswordConfirmBlur(e, this.state.fields.new_password)}}/>
                                    </Paper>
                                </div>
                            </div>
                            <div className='center'>
                                <Button className='submit primary' onClick={this.onSubmit}
                                        disabled={this.isSubmitDisabled()}>
                                    {translator('changePassword.submit')}
                                </Button>
                            </div>
                        </div>
                    </div>
                    {this.state.success && <Navigate to={"/account/profile"}/>}
                </div>
            </div>
        )
    }
}

export default WithCheckKYC(WithTokenSupport(WithTranslator(ChangePasswordPage)));
