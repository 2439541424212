export const PAYOUT_STATUS = {
    PENDING: 'PENDING',
    WAITING_3DS: 'WAITING_3DS',
    TRANSFERED: 'TRANSFERED',
    WAITING_CONTINUE_TRANSFER: 'WAITING_CONTINUE_TRANSFER',
    FAILED_CONTINUE_TRANSFER: 'FAILED_CONTINUE_TRANSFER',
    FAILED: 'FAILED',
};

export const RESULT_STATUS = {
    WAIT: 'wait',
    SUCCESS: 'success',
    ERROR: 'warning',
    LIMIT: 'limit',
    CODE: 'code',
}

export const LOGIN_STEP = {
    FIRST: 'first',
    NEED_TFA: 'need_tfa',
    FORCED_TFA: 'forced_tfa',
    KYC: 'kyc',
}

// User Status
export const AUTHORIZED = 'authorized'
export const AUTHORIZING = 'authorizing'
export const UNAUTHORIZED = 'unauthorized'

// reviewAnswer
export const REVIEW_ANSWER = {
    RED: 'RED',
    GREEN: 'GREEN',
}

// account pages
export const ACCOUNT_PAGES = {
    'transfers': 'transfers',
    'send': 'send',
    'receive': 'receive',
    'profile': 'profile',
    'change-password': 'profile',
    'show-tfa': 'profile',
    'change-tfa': 'profile',
}

export const STATUS_COLORS = {
    'successful': 'var(--color-primary)',
    'declined': 'var(--color-red)',
    'refund': 'var(--color-text)',
    'refund_declined': 'var(--color-red)'
}
