import React from 'react';
import WithTranslator from '../../../../providers/WithTranslator';
import ReactHtmlParser from "react-html-parser";

import './AdvantagesPart.scss';
import icon1 from '../../../../resources/advantages1.png';
import icon2 from '../../../../resources/advantages2.png';
import icon3 from '../../../../resources/advantages3.png';

class AdvantagesPart extends React.Component {
    render() {
        const translator = this.props.translator;
        return (
            <>
                <a name="advantages" />
                <div className='advantages-part' id='advantagesPartBlock'>
                    <span className='h2 _center'>{translator('advantagesPart.title')}</span>
                    <div className='container'>
                        {[icon1, icon2, icon3].map((icon, i) =>
                            <div key={i} className='item'>
                                <img src={icon} alt=''/>
                                <span className='h3'>{translator(`advantagesPart.title${i}`)}</span>
                                <span>{ReactHtmlParser(translator(`advantagesPart.text${i}`))}</span>
                            </div>
                        )}
                    </div>
                </div>
            </>
        )
    }
}

export default WithTranslator(AdvantagesPart);
