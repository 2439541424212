import React from 'react';
import {Link, Navigate} from "react-router-dom";
import apiService from "../../../../services/apiService";
import withCheckKYC from "../../../../providers/WithCheckKYC";
import WithTranslator from "../../../../providers/WithTranslator";
import {withLocation} from "../../../../services/hooks";
import EnableTFA from "../../../tfa/EnableTFA";
import {ReactComponent as IconBack} from "../../../../resources/icons/icon-back.svg";
import {pages} from "../../../../providers/WithCurrentPage";
import {AUTHORIZING, UNAUTHORIZED} from "../../../../constants";


class ChangeTFAForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            success: false,
        };
    }

    componentDidMount() {
        document.addEventListener('keydown', this.customHandleKeyDown)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.customHandleKeyDown);
    }

    customHandleKeyDown = (e) => {
        if (e.key !== 'Enter') return;
        let submitEl = document.querySelector('.submit');
        if (!submitEl.disabled){
            submitEl.click();
        }
    }

    handleResponse = (response) => {
        return {errors: response?.errors?.data || {}};
    }

    handleSuccess = (value) => {
        this.setState({success: value});
    }

    render() {
        const translator = this.props.translator;

        return (
            <>
                {this.props.location.state?.from === "dialogForm"
                ?
                <div className="transfer-part account-part">
                    <div className='transfer-part__inner'>
                        <Link to={'/account/profile'} className='back-link'>
                            <IconBack/>
                            <span>{translator('backLink.back')}</span>
                        </Link>
                        <EnableTFA getTFARequest={apiService.generateNewTFA}
                                   onResponse={this.handleResponse}
                                   onSuccess={this.handleSuccess}/>
                    </div>
                    {this.state.success && <Navigate to={'/account/profile'}/>}
                </div>
                :
                 <Navigate to={'/account/profile'}/>
                }
            </>
        )
    }
}

export default withLocation(withCheckKYC(WithTranslator(ChangeTFAForm)));
