import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import WithTranslator from "./providers/WithTranslator";
import MainTransferSendPage from "./components/pages/transferSend/MainTransferSendPage";
import MainTransferReceivePage from "./components/pages/transferReceive/MainTransferReceivePage";
import LoginUser from "./components/pages/login/LoginUser";
import ForgotPassword from "./components/pages/password/ForgotPassword";
import ResetPassword from "./components/pages/password/ResetPassword";
import RegisterUser from "./components/pages/register/RegisterUser";
import MainPage from "./components/pages/main/MainPage";
import MainLayout from "./components/layouts/MainLayout";
import AccountLayout from "./components/layouts/AccountLayout";
import TransfersPage from "./components/pages/account/transfers/TransfersPage";
import TransferDetailPage from "./components/pages/account/transfers/TransferDetailPage";
import ProfilePage from "./components/pages/account/profile/ProfilePage";
import ChangePasswordPage from "./components/pages/account/changePassword/ChangePasswordPage";
import ChangeTFAPage from "./components/pages/account/changeTFA/ChangeTFAPage";
import ShowTFAPage from "./components/pages/account/showTfa/ShowTFAPage";
import AccountTransferSendPage from "./components/pages/account/send/AccountTransferSendPage";
import AccountTransferReceivePage from "./components/pages/account/receive/AccountTransferReceivePage";


class App extends React.Component {

    render() {
        return (
            <Router basename="">
                <Routes>
                    <Route path='/' element={<MainLayout/>}>
                        <Route path='' element={<MainPage/>}/>
                        <Route path='*' element={<Navigate to={'/'}/>}/>
                        <Route path='login' element={<LoginUser/>}/>
                        <Route path='forgot-password' element={<ForgotPassword/>}/>
                        <Route path='reset-password' element={<ResetPassword />}/>
                        <Route path='register' element={<RegisterUser/>}/>
                        <Route path='transfer-receive' element={<MainTransferReceivePage/>}/>
                        <Route path='transfer-send' element={<MainTransferSendPage/>}/>
                    </Route>
                    <Route path={'/account'} element={<AccountLayout/>}>
                        <Route path='' element={<Navigate to={'/account/transfers'} replace/>}/>
                        <Route path='*' element={<Navigate to={'/account/transfers'} replace/>}/>
                        <Route path='transfers' element={<TransfersPage/>}/>
                        <Route path='transfers/:id/' element={<TransferDetailPage/>}/>
                        <Route path='profile' element={<ProfilePage/>}/>
                        <Route path='change-password' element={<ChangePasswordPage/>}/>
                        <Route path='change-tfa' element={<ChangeTFAPage/>}/>
                        <Route path='show-tfa' element={<ShowTFAPage />}/>
                        <Route path='send' element={<AccountTransferSendPage />}/>
                        <Route path='receive' element={<AccountTransferReceivePage />}/>
                    </Route>
                </Routes>
            </Router>
        )
    }
}

export default WithTranslator(App);
