import React from 'react';
import jwt from 'jwt-decode';
import TransferSendFirstStep from './TransferSendFirstStep';
import TransferSendSecondStep from './TransferSendSecondStep';
import TransferResultStep from './TransferResultStep';
import TransferFreeDS from './TransferFreeDS';
import WithTranslator from '../../providers/WithTranslator';
import WithTokenSupport from "../../providers/WithTokenSupport";
import WithCurrentPage, {pages} from "../../providers/WithCurrentPage";
import apiService from '../../services/apiService';
import {RESULT_STATUS as STATUS, REVIEW_ANSWER} from '../../constants';

class TransferSend extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            _3ds: null,
            trId: null,
            step: 1,
            firstFields: null,
            secondFields: null,
            status: STATUS.FORM,
        };
    }

    componentDidMount() {
        const trId = window.location.href.split('?trId=')[1];
        if (trId) {
            this.setState({status: STATUS.WAIT}, () => {
                this.sendQuery(() => apiService.getStatus(decodeURIComponent(trId)));
            });
        }
    }

    sendQueryWithCheck = (data) => {
        this.props.sendRequest(apiService.checkApplicant).then((response) => {
            if (!response?.errors) {
                const reviewResult = response?.reviewResult;
                if (reviewResult && reviewResult?.reviewAnswer === REVIEW_ANSWER.GREEN) {
                    this.props.sendRequest(apiService.checkLimit).then((response) => {
                        if (!response.errors){
                            if (data?.amount?.value <= +response.current_limit) {
                                this.sendQuery(() => apiService.startTransfer(data));
                            } else {
                                this.setState({status: STATUS.LIMIT})
                            }
                        } else {
                            this.setState({status: STATUS.ERROR});
                        }
                    })
                } else {
                    window.location.reload();
                }
            }
        })
    }

    onSubmitFirstStep = fields => {
        if (fields.deferPayout) {
            const data = this.getData(fields);
            this.setState({status: STATUS.WAIT, firstFields: {...fields}});
            this.sendQueryWithCheck(data);
        } else {
            this.setState({step: 2, firstFields: {...fields}}, () => window.scrollTo({top: 0}));
        }
    }

    onSubmitSecondStep = fields => {
        const data = this.getData(this.state.firstFields);
        if (fields.deferPayout) {
            data['deferPayout'] = true;
        } else {
            const [month, year] = fields.date.split('/');
            data['to'] = {
                'cardNumber': fields.card.replaceAll(' ', ''),
                'expMonth': +month,
                'expYear': +year,
            };
        }
        this.setState({status: STATUS.WAIT, secondFields: fields}, () => window.scrollTo({top: 0}));
        this.sendQueryWithCheck(data);
    }

    onCancelFirstStep = () => this.setState({firstFields: null, secondFields: null});
    onCancelSecondStep = () => this.setState({step: 1});

    sendQuery = startPromise => startPromise().then(state => this.setState({...state}, () => window.scrollTo({top: 0})));

    getUser = () => {
        const token = localStorage.getItem("access");
        return token ? jwt(token)?.user_id : null
    }

    getData = firstFields => {
        const [month, year] = firstFields.date.split('/');
        return {
            'deferPayout': firstFields.deferPayout,
            'userid': this.getUser(),
            'amount': {
                'value': +firstFields.amount,
                'currency': firstFields.currency.toUpperCase(),
                'fee': +firstFields.fee,
            },
            'from': {
                'cardNumber': firstFields.card.replaceAll(' ', ''),
                'cardHolder': firstFields.owner,
                'expMonth': +month,
                'expYear': +year,
                'cvc': +firstFields.cvv,
            },
            '3ds': {
                'redirectUri': window.location.href,
            },
        }
    };

    onStart = () => {
        const state = (this.state.status === STATUS.ERROR || this.state.status === STATUS.LIMIT)
            ? {step: 1, status: STATUS.FORM}
            : {step: 1, status: STATUS.FORM, firstFields: null, secondFields: null};
        this.setState(state, () => window.scrollTo({top: 0}));
        // ToDo: по возможности улучшить (рассмотреть в uri в запросах на тран
        if (location.href.includes('?')) {
            history.pushState({}, null,
                this.props.currentPage === pages.Account
                    ? '/account/send':  '/transfer-send'
            );
        }
    }

    render() {
        return (
            <div className={`container _dark`}>
                <div className='transfer'>

                    {this.state.status === STATUS.FORM &&
                    (this.state.step === 1
                            ?
                            <TransferSendFirstStep fields={this.state.firstFields}
                                                   onSubmit={this.onSubmitFirstStep}
                                                   onCancel={this.onCancelFirstStep}/>
                            : this.state.step === 2 &&
                            <TransferSendSecondStep fields={this.state.secondFields}
                                                    onSubmit={this.onSubmitSecondStep}
                                                    onCancel={this.onCancelSecondStep}
                                                    currency={this.state.firstFields?.currency}
                                                    amount={this.state.firstFields?.amount}/>

                    )}

                    {this.state.status !== STATUS.FORM &&
                    <TransferResultStep status={this.state.status} onSubmit={this.onStart}
                                        trId={this.state.trId}
                                        currency={this.state.firstFields?.currency}
                                        amount={this.state.firstFields?.amount}/>}
                </div>

                <TransferFreeDS _3ds={this.state._3ds}/>
            </div>
        )
    }
}

export default WithTokenSupport(WithCurrentPage(WithTranslator(TransferSend)));
