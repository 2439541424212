import React from 'react';
import HeaderAppBar from './HeaderAppBar';
import HeaderMobileMenu from './HeaderMobileMenu';
import WithTranslator from '../../../providers/WithTranslator';

import './Header.scss';
import WithCurrentPage, {pages} from "../../../providers/WithCurrentPage";

const notVisible = {
    mainPartVisible: false,
    advantagesPartVisible: false,
    about1PartVisible: false,
    about2PartVisible: false,
};

const firstVisible = {
    mainPartVisible: true,
    advantagesPartVisible: false,
    about1PartVisible: false,
    about2PartVisible: false,
};

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mainPartVisible: false
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll, {passive: true});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentPage === pages.Main && this.props.currentPage !== pages.Main) {
            this.setState({...notVisible});
        } else if (prevProps.currentPage !== pages.Main && this.props.currentPage === pages.Main) {
            this.setState({...firstVisible})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    getParts() {
        return {
            mainPart: document.getElementById('mainPartBlock'),
            advantagesPart: document.getElementById('advantagesPartBlock'),
            about1Part: document.getElementById('about1PartBlock'),
            about2Part: document.getElementById('about2PartBlock'),
        }
    }

    onScroll = () => {
        let visible = {...notVisible};

        if (this.props.currentPage === pages.Main) {
            let maxShift = 0;
            const mainPageParts = this.getParts();

            for(const name in mainPageParts) {
                if (mainPageParts[name] == null) {
                    return
                }
                const position = mainPageParts[name].getBoundingClientRect();
                if (position.top >= 0 && position.bottom <= window.innerHeight) {
                    visible = {...notVisible, [`${name}Visible`]: true};
                    break;
                } else if (position.top < window.innerHeight && position.bottom >= 0) {
                    const height = position.top > 0
                        ? window.innerHeight - position.top
                        : position.bottom;
                    if (height >= maxShift) {
                        maxShift = height;
                        visible = {...notVisible, [`${name}Visible`]: true};
                    }
                }
            }
        }
        const fixed = window.pageYOffset > 70;
        this.setState({...visible, fixed});
    }

    render() {
        const props = {
            ...this.state,
        }
        return (
            <>
                <HeaderAppBar {...props}/>
                <HeaderMobileMenu {...props}/>
            </>
        )
    }
}

export default WithCurrentPage(WithTranslator(Header));
