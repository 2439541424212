import React from 'react';
import ReactHtmlParser from "react-html-parser";
import WithTranslator from '../../../providers/WithTranslator';

import {ReactComponent as MapIcon} from "../../../resources/icons/map.svg";
import {ReactComponent as RequestIcon} from "../../../resources/icons/request.svg";

import './Footer.scss';

class AccountFooter extends React.Component {
    openMap = () => {
        // const url = this.props.language === 'ru'
        //     ? 'https://goo.gl/maps/a6pbx17GdpFg6gzZ7'
        //     : 'https://goo.gl/maps/FJiueRD4KvqUdgzMA';

        const url = "https://goo.gl/maps/3ziGxZqQSDS7o5zX7";
        window.open(url);
    }

    openFeedback = () => this.props.openFeedback();

    render() {
        const translator = this.props.translator;
        return (
            <footer className='footer account'>
                <div className='item'>
                    <span className='h3'>{ReactHtmlParser(translator('footer.title1'))}</span>
                    <span className='text'>{ReactHtmlParser(translator('footer.text1'))}</span>
                    <a className='button'  onClick={this.openMap}>
                        <MapIcon />&nbsp;
                        {translator('footer.btn.address')}
                    </a>
                </div>

                <div className='item'>
                    <div>
                        <span className='h3'>{ReactHtmlParser(translator('footer.agreements'))}</span>
                        <a className='link' href={`/terms-webtranspay-${this.props.language}.pdf`} target='_blank'>
                            {ReactHtmlParser(translator('footer.agreements.terms'))}
                        </a>
                        <a className='link' href={`/terms-of-service-webtranspay-${this.props.language}.pdf`} target='_blank'>
                            {ReactHtmlParser(translator('footer.agreements.terms.service'))}
                        </a>
                        <a className='link' href={`/policy-webtranspay-${this.props.language}.pdf`} target='_blank'>
                            {ReactHtmlParser(translator('footer.agreements.policy'))}
                        </a>
                    </div>
                    <span className='text rights only-desktop'>{translator('footer.agreements.rights')}</span>
                </div>

                <div className='item'>
                    <span className='h3'>{translator('footer.title2')}</span>
                    <span className='text'>{ReactHtmlParser(translator('footer.text2'))}</span>
                    <a className='button' onClick={this.openFeedback}>
                        <RequestIcon /> &nbsp;
                        {translator('footer.btn.feedback')}
                    </a>
                </div>

                <div className='item only-mobile'>
                    <span className='text rights'>{translator('footer.agreements.rights')}</span>
                </div>
            </footer>
        )
    }
}

export default WithTranslator(AccountFooter);
