import React from 'react';
import {Link} from "react-router-dom";
import {Button, Modal} from '@material-ui/core';
import WithTranslator from '../../providers/WithTranslator';
import {ReactComponent as IconCancel} from "../../resources/icons/icon-cancel.svg";

class DialogForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const translator = this.props.translator;
        return (
            <Modal
                open={this.props.open}
                className='contacts-part'
            >
                <form className='dialog-form'>
                    <div className='header center'>
                        <div className='empty'/>
                        <div className='title'>
                            <span className='h3'>{this.props.title}</span>
                        </div>
                        <a className='close-btn' onClick={this.props.onCancel}>
                            <IconCancel />
                        </a>
                    </div>
                    <div className={'center'}>
                        <div className={'margin-bottom body-text'}>{this.props.info}</div>
                        <Link to={this.props.onSubmitPath} state={{ from: "dialogForm" }}>
                            <Button className='login-button _active'>
                                {this.props.submitName}
                            </Button>
                        </Link>
                        <Button className='login-button cancel-button no-space' onClick={this.props.onCancel}>
                            {translator('dialogForm.btn.cancel')}
                        </Button>
                    </div>
                </form>
            </Modal>
        )
    }
}

export default WithTranslator(DialogForm);
