const isEmailValid = v => {
    const isValid = !!v ? /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) : true;
    return {
        valid: isValid,
        detail: isValid ? '': 'Wrong or Invalid email address. Please correct and try again',
    };
}

const isPasswordValid = v => {
    if (!v) {
        return {
            valid: true,
            detail: ''
        }
    }
    const hasUpperCase = /[A-Z]/.test(v);
    const hasLowerCase = /[a-z]/.test(v);
    const hasNumber = /[0-9]/.test(v);
    const hasSpecialCharacters = /[ !"#$%&'()*+,\-./:;<=>?@\[\]\\^_`{|}~]/.test(v);
    const hasMinLength = v.length >= 10;

    let errorText = []
    if (!hasUpperCase) errorText.push('1 uppercase character');
    if (!hasLowerCase) errorText.push('1 lowercase character');
    if (!hasNumber) errorText.push('1 number');
    if (!hasSpecialCharacters) errorText.push('1 special character');
    if (!hasMinLength) errorText.push('10 characters in total');

    const isValidResult = errorText.length === 0;
    return {
        valid: isValidResult,
        detail: isValidResult ? '': 'Password must contain at least ' + errorText.join(', ')
    }
}

const isNameValid = v => {
    const isValid = !!v ? /^[A-Za-z]+$/.test(v) : true;
    return {
        valid: isValid,
        detail: isValid ? '': 'Name must contain only Latin letters',
    };
}

export default {
    isEmailValid,
    isPasswordValid,
    isNameValid,
}
