import React from 'react';
import {Button, Paper} from '@material-ui/core';
import TextInput from '../../textInpit/TextInput';
import WithTranslator from '../../../providers/WithTranslator';
import WithTokenSupport from "../../../providers/WithTokenSupport";
import apiService from '../../../services/apiService';
import FieldsHelper from '../../common/FieldsHelper';
import InfoForm from "../../common/InfoForm";


const defaultFields = {
    key: '',
};

class CheckTFACode extends React.Component {
    constructor(props) {
        super(props);

        this.FHelper = new FieldsHelper(this);
        this.state = {
            fields: defaultFields,
            isPopupShown: false,
            errors: {},
        };
    }

    onSubmit = () => {
        const errors = {
            key: !this.state.fields.key,
        };
        if (Object.values(errors).some(i => !!i)) {
            this.setState({errors: {...errors}});
        } else {
            this.send();
        }
    }

    send = () => {
        const data = {
            'key': this.state.fields.key,
        }
        this.props.sendRequest(apiService.verifyTfa, data).then(response => {
            const new_state = this.props.onResponse(response);
            this.setState({...new_state}, () => window.scrollTo({top: 0}))
            if (!Object.keys(new_state.errors).length) {
                this.props.onSuccess(true);
            }
        });
    }

    showPopup = () => {
        this.setState({isPopupShown: true});
    }

    closePopup = () => {
        this.setState({isPopupShown: false});
    }

    isSubmitDisabled = () => {
        return !Object.entries(this.state.fields).every(([k, v]) => !!v)
    }

    render() {
        const translator = this.props.translator;
        return (
            <div className='transfer'>
                <div>
                    <span className='header'>
                        {translator('loginUserPart.needTfa.title')}
                    </span>
                    <div className='cards'>
                        <Paper className='card'>
                            <span>{translator('loginUserPart.needTfa.info')}</span>
                            <TextInput label={translator('loginUserPart.form.code')}
                                       withErrorText autoComplete='off'
                                       className='margin-top'
                                       placeholder='111111'
                                       error={!!this.state.errors.key}
                                       errorText={this.state.errors.key}
                                       value={this.state.fields.key}
                                       onChange={this.FHelper.onKeyChange}/>
                            <div className='margin-top'>
                                <a onClick={this.showPopup}>{translator('loginUserPart.needTfa.popup.title')}</a>
                            </div>
                            <InfoForm
                                open={this.state.isPopupShown}
                                onClosePath={'/login'}
                                onClose={this.closePopup}
                                title={translator('loginUserPart.needTfa.popup.title')}
                                info={translator('loginUserPart.needTfa.popup.text')}
                            />
                        </Paper>
                    </div>
                </div>
                <div className='center'>
                    <Button className='submit primary' onClick={this.onSubmit}
                            disabled={this.isSubmitDisabled()}>
                        {translator('loginUserPart.needTfa.btn.submit')}
                    </Button>
                </div>
            </div>
        )
    }
}

export default WithTokenSupport(WithTranslator(CheckTFACode));
