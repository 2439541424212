import React from 'react';
import Main from "./mainPart/MainPart";
import AdvantagesPart from "./advantagesPart/AdvantagesPart";
import AboutPart from "./aboutPart/AboutPart";
import WithCurrentPage, {pages} from "../../../providers/WithCurrentPage";
import RegistrationPopup from "../popups/RegistrationPopup";

class MainPage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.setCurrentPage(pages.Main)
    }

    componentWillUnmount() {
        this.props.setCurrentPage(pages.Other)
    }

    render() {
        return (
            <>
                <div className='app'>
                    <Main/>
                    <AdvantagesPart/>
                    <AboutPart/>
                </div>
                <RegistrationPopup />
            </>
        )
    }
}

export default WithCurrentPage(MainPage);
