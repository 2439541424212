import React from 'react';
import {Link} from "react-router-dom";
import {AppBar} from '@material-ui/core';
import WithTranslator from '../../../providers/WithTranslator';
import WithTokenSupport from "../../../providers/WithTokenSupport";

import { ReactComponent as MainPageIcon } from "../../../resources/icons/main-page.svg";
import { ReactComponent as SignOutIcon } from "../../../resources/icons/sign-out.svg";

import './Header.scss';

class AccountHeaderDesktop extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const translator = this.props.translator;
        return (
            <>
                <AppBar className="appbar account" id='header'>
                    <Link to={'/'} className="link">
                        <MainPageIcon/>&nbsp;
                        <span>{translator("account.header.mainPage")}</span>
                    </Link>

                    <Link to={'/'} className="link" onClick={() => {this.props.removeTokens()}}>
                        <SignOutIcon/>&nbsp;
                        <span>{translator("account.header.signOut")}</span>
                    </Link>
                </AppBar>
            </>
        )
    }
}

export default WithTokenSupport(WithTranslator(AccountHeaderDesktop));
