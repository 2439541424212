import {useParams, useNavigate, useLocation} from "react-router-dom";
import React from "react";

export  function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

export function withNavigate(Component) {
    return props => <Component {...props} navigate={useNavigate()} />;
}

export  function withLocation(Component) {
    return props => <Component {...props} location={useLocation()}/>
}
