import React from 'react';
import {Link} from "react-router-dom";
import {Button} from '@material-ui/core';
import WithTranslator from '../../../providers/WithTranslator';
import {AUTHORIZED} from "../../../constants";
import WithTokenSupport from "../../../providers/WithTokenSupport";

const LoginControl = function ({translator, userStatus}) {
    return (
        <div>
            {userStatus === AUTHORIZED
                ?
                <div>
                    <Link to={'/account'}>
                        <Button className='login-button _active'>
                            {translator('header.btn.account')}
                        </Button>
                    </Link>
                </div>
                :
                <div>
                    <Link to={'/login'}>
                        <Button className='login-button _active'>
                            {translator('header.btn.logIn')}
                        </Button>
                    </Link>

                    <Link to={'/register'}>
                        <Button className='login-button'>
                            {translator('header.btn.signUp')}
                        </Button>
                    </Link>
                </div>
            }
        </div>
    )
}

export default WithTokenSupport(WithTranslator(LoginControl));
