export default {
    'header.link.main': 'Transfer',
    'header.link.advantagesPart': 'Advantages',
    'header.link.about1': 'To the sender',
    'header.link.about2': 'To the recipient',
    'header.link.commission': 'Conditions',
    'header.lang.ru': 'RU',
    'header.lang.en': 'ENG',
    'header.btn.logIn': 'Log in',
    'header.btn.signUp': 'Sign Up',
    'header.btn.logOut': 'Log out',
    'header.btn.account': 'Go to account',

    'infoForm.btn.ok': 'OK',
    'dialogForm.btn.ok': 'OK',
    'dialogForm.btn.cancel': 'Cancel',

    'mainPart.title': 'Money transfers<br/>from card to card',
    'mainPart.btn.makeTransfer': 'Make transfer',
    'mainPart.btn.receiveTransfer': 'Receive transfer',
    'mainPart.sendTransfer.warning.title': 'Send Transfer',
    'mainPart.sendTransfer.warning.info': 'To send transfer you need to register and pass verification. If you want to register press "SIGN UP"',
    'mainPart.sendTransfer.warning.btn.submit': 'Sing up',

    'aboutPart.title1': 'How to transfer?',
    'aboutPart.title2': 'How to receive?',
    'aboutPart.card1.title': 'If I know the recipient\'s card details',
    'aboutPart.card2.title': 'If I don\'t know the recipient\'s card details',
    'aboutPart.card3.title': 'If the sender entered my card details',
    'aboutPart.card4.title': 'If the sender sent me a transfer code',
    'aboutPart.card1.text': 'Enter the transfer amount <br/> Enter your card details <br/> Agree to the Terms of Use <br/> Click "Next" <br/> Enter the number and expiration date of the recipient\'s card <br/> Done!',
    'aboutPart.card2.text': 'Enter the transfer amount <br/> Enter your card details <br/> Agree to the Terms of Use <br/> Click "Next" <br/> Get a transfer code <br/> Copy the transfer code and send it to the recipient <br/> Done!',
    'aboutPart.card3.text': 'You just need to wait for the transfer to be completed! <br/> The terms for crediting funds to the recipient\'s card account depends on the recipient\'s card\'s issuing bank and can vary from a couple of minutes to several days.',
    'aboutPart.card4.text': 'Go to the tab receive transaction <br/> Insert the transaction code <br/> Click "Receive Transfer" button <br/> Done!',

    'transferPart.title': 'Transfer',
    'transferPart.form.card1.title': 'Sender card',
    'transferPart.form.card2.title': 'Recipient\'s card',
    'transferPart.form.amount.title': 'Amount',
    'transferPart.menu.btn.makeTransfer': 'Send transfer',
    'transferPart.menu.btn.receiveTransfer': 'Receive transfer',
    'transferPart.form.currency.rub': '₽ Ruble',
    'transferPart.form.currency.usd': 'USD',
    'transferPart.form.currency.eur': 'EUR',
    'transferPart.form.currency.hkd': 'HKD',
    'transferPart.form.amount.placeholder': 'Transfer amount',
    'transferPart.form.currency.placeholder': 'Currency',
    'transferPart.form.card1.placeholder': 'Sender card number',
    'transferPart.form.card2.placeholder': 'Recipient\'s card number',
    'transferPart.form.owner.placeholder': 'Card owner',
    'transferPart.form.date.placeholder': 'Month/Year',
    'transferPart.form.cvv.placeholder': 'CVV',
    'transferPart.form.commission1': 'Fee amount ',
    'transferPart.form.commission2': 'Commission will be ',
    'transferPart.form.commission3': 'Total ',
    'transferPart.form.agree1': 'I agree with the conditions',
    'transferPart.form.agree2': 'offers',
    'transferPart.form.code': 'Get transfer code',
    'transferPart.form.code.description': 'What is it?',
    'transferPart.form.code.tooltip': 'If you do not know the recipient\'s card details, you can get the transfer code. After sending the code to the recipient, he will enter the card on which he will receive money on his own',
    'transferPart.form.btn1.submit': 'Next',
    'transferPart.form.btn1.cancel': 'Reset',
    'transferPart.form.btn2.submit': 'Send',
    'transferPart.form.btn2.cancel': 'Back',
    'transferPart.form.get.submit': 'Receive',
    'transferPart.result.wait.title': 'Transaction verification',
    'transferPart.result.wait.description': 'It can take a few seconds, please don\'t close or refresh the page',
    'transferPart.result.code.title': 'Transfer code',
    'transferPart.result.code.description': 'Copy the code and send it to the recipient',
    'transferPart.result.code.submit': 'New transfer',
    'transferPart.result.success.title': 'Your money is on the way',
    'transferPart.result.success.description': 'Transfer is complete',
    'transferPart.result.success.submit': 'New transfer',
    'transferPart.result.warning.title': 'Error',
    'transferPart.result.warning.description': 'Transfer is failed, please try again',
    'transferPart.result.warning.submit': 'Try again',
    'transferPart.result.limit.title': 'Limit exceeded',
    'transferPart.result.limit.description': 'The transfer amount exceeds the limit. To increase the limit, please contact support.',
    'transferPart.result.limit.submit': 'OK',

    'loginUserPart.link.forgotPassword': 'Forgot your password?',
    'loginUserPart.link.dontHaveAccount': 'Don\'t have a Webtranspay account?',
    'loginUserPart.link.haveAccount': 'Already have an account? Log in',
    'loginUserPart.form.email': 'Email',
    'loginUserPart.form.password': 'Password',
    'loginUserPart.form.firstName': 'First name',
    'loginUserPart.form.lastName': 'Last name',
    'loginUserPart.form.code': 'Code',
    'loginUserPart.form.key': 'Key',
    'loginUserPart.form.tfa': 'Key',
    'loginUserPart.form.confirmPassword': 'Confirm password',
    'loginUserPart.form.newPassword': 'New password',
    'loginUserPart.form.confirmNewPassword': 'Confirm new password',
    'loginUserPart.loginUser.title': 'Log in',
    'loginUserPart.loginUser.btn.submit': 'Log in',
    'loginUserPart.forgotPassword.title': 'Password Recovery',
    'loginUserPart.forgotPassword.btn.submit': 'Reset password',
    'loginUserPart.forgotPassword.info': 'Please enter the email address is linked to your Webtranspay account.',
    'loginUserPart.forgotPassword.popup.title': 'Password Recovery',
    'loginUserPart.forgotPassword.popup.info': 'If this email address is linked to a Webtranspay account, we will email you instructions for resetting your password.',
    'loginUserPart.resetPassword.title': 'Password Recovery',
    'loginUserPart.resetPassword.btn.submit': 'Reset password',
    'loginUserPart.resetPassword.popup.title': 'Password recovery complete',
    'loginUserPart.resetPassword.popup.info': 'Now you can log in to your account.',
    'loginUserPart.resetPassword.popup.error.title': 'Failed password recovery',
    'loginUserPart.resetPassword.popup.error.signatureExpired': 'The link you followed has expired',
    'loginUserPart.resetPassword.popup.error.signatureError': 'The link you followed has invalid signature',
    'loginUserPart.registerUser.title': 'Sign up',
    'loginUserPart.registerUser.step1.btn.submit': 'Sign up',
    'loginUserPart.registerUser.step1.passwordPrompt': 'Password must contain at least 10 symbols of which 1 must be uppercase character, 1 - lowercase character, 1 - special symbol and 1 - number',
    'loginUserPart.registerUser.step1.agree1':'I agree with',
    'loginUserPart.registerUser.step1.terms': 'terms of use',
    'loginUserPart.registerUser.step1.agree2':'and',
    'loginUserPart.registerUser.step1.policy': 'privacy policy',
    'loginUserPart.registerUser.waitEmail.title': 'Email confirmation',
    'loginUserPart.registerUser.waitEmail.info': 'We have sent you an email confirmation link. Please check your email.',
    'loginUserPart.registerUser.registered.title': 'Email address confirmed',
    'loginUserPart.registerUser.registered.info': 'Please log in to your account to complete the registration: 2FA set-up and KYC procedure',
    'loginUserPart.registerUser.popup.error.title': 'Failed email confirmation',
    'loginUserPart.registerUser.popup.error.signatureExpired': 'The link you followed has expired',
    'loginUserPart.registerUser.popup.error.signatureError': 'The link you followed has invalid signature',
    'loginUserPart.needTfa.title': 'Enter 2FA Code',
    'loginUserPart.needTfa.btn.submit': 'Log in',
    'loginUserPart.needTfa.info':'Open Google Authenticator or Authy on your device. You\'ll find a 6-digit code in the app, please enter it in the field below:',
    'loginUserPart.needTfa.popup.title':'How to reset two-factor authentication?',
    'loginUserPart.needTfa.popup.text':'To reset two-factor authentication please contact support.',

    'commissionPart.title': 'Conditions',
    'commissionPart.commission': 'Commission:',
    'commissionPart.limit': 'Limit:',
    'commissionPart.title11': 'Internal transfers',
    'commissionPart.title12': 'Between cards of Russian banks',
    'commissionPart.title21': 'Cross-border transfers',
    'commissionPart.title22': 'from the RF card to foreign',
    'commissionPart.commission1': '1,5%',
    'commissionPart.commission2': '2%',
    'commissionPart.limit1': 'Minimum 50 ₽',
    'commissionPart.limit2': 'Minimum 200 ₽',
    'commissionPart.warning.title': 'Timing',
    'commissionPart.warning': 'The term for crediting funds to the recipient\'s card account depends on the bank that issued the recipient\'s card and can range from several minutes to several days',

    'advantagesPart.title': 'Advantages',
    'advantagesPart.title0': 'Simple transfers',
    'advantagesPart.text0': 'You can transfer money not using<br/>the recipient\'s card details',
    'advantagesPart.title1': 'Various banks',
    'advantagesPart.text1': 'Transfer funds between<br/>different banks',
    'advantagesPart.title2': 'International transfers',
    'advantagesPart.text2': 'You can send money<br/>to cards abroad',

    'contactsPart.form.title': 'Technical support',
    'contactsPart.form.email': 'Email',
    'contactsPart.form.name': 'Name',
    'contactsPart.form.name.placeholder': 'John',
    'contactsPart.form.transaction': 'Transaction ID',
    'contactsPart.form.message': 'Your message',
    'contactsPart.form.message.placeholder': 'Message',
    'contactsPart.form.btn.submit': 'Send message',
    'contactsPart.wait.title': 'Feedback is sending',
    'contactsPart.success.title': 'Feedback sent',
    'contactsPart.warning.title': 'Something went wrong',
    'contactsPart.warning.btn': 'Try again\n',
    'contactsPart.email.subject': trId => `Feedback (${trId})`,
    'contactsPart.email.message': (name, email, trId, message) => `Username:\n${name}\n\nUser mail:\n${email}\n\nTransaction ID:\n${trId}\n\nMessage:\n${message}`,

    'transferDetail.title': 'Transaction',
    'transferDetail.card.transactionId': 'Transaction id',
    'transferDetail.card.created': 'Created',
    'transferDetail.card.transferAmount': 'Transfer amount',
    'transferDetail.card.fee': 'Fee',
    'transferDetail.card.totalAmount': 'Total amount',
    'transferDetail.card.currency': 'Currency',
    'transferDetail.card.status': 'Status',
    'transferDetail.card.cardNumber': 'Card',
    'transferDetail.card.cardholder': 'Cardholder',
    'transferDetail.card.transferCode': 'Transfer code',

    'enableTFA.title': 'Two-factor Authentication (2FA)',
    'enableTFA.btn.submit': 'Enable',
    'enableTFA.btn.update': 'Update',
    'showTFA.info.title': 'Two-factor Authentication (2FA)',
    'showTFA.info.QR': 'Download and open Google Authenticator or Authy on your device and scan the QR code below:',
    'showTFA.info.Key': 'If you can\'t scan the QR code, enter the text key in the app manually:',
    'enableTFA.info.Code': 'You\'ll find a 6-digit code in the app, please enter it in the field below:',

    'profile.title': 'Profile',
    'profile.personalInfo.title': 'Personal Information',
    'profile.personalInfo.firstName.title': 'First name',
    'profile.personalInfo.lastName.title': 'Last name',
    'profile.personalInfo.email.title': 'E-mail',
    'profile.tfa.title': '2FA',
    'profile.tfa.newDevice.title': 'Add a new device',
    'profile.tfa.newSecret.title': 'Generate new security key',
    'profile.tfa.add.btn': 'Add +',
    'profile.kyc.title': 'KYC',
    'profile.kyc.status.title': 'Status',
    'profile.changePassword.btn': 'Change password',

    'changePassword.title': 'Password Change',
    'changePassword.field.oldPassword': 'Old password',
    'changePassword.field.newPassword': 'New password',
    'changePassword.field.confirmNewPassword': 'Confirm new password',
    'changePassword.submit': 'Change password',

    'changeTFA.warning.title': 'Note',
    'changeTFA.warning.info': 'All devices linked to the old security key will stop the generation of valid dynamic six-digit one-time passwords. After the generation of the new security key, please add it to at least one of your devices using Google Authenticator or Authy.',
    'changeTFA.warning.btn.submit': 'Ok',

    'footer.title1': 'Asia TransPay (HK) Limited<br/>Company Number: 2676084',
    // 'footer.text1': 'Unit 2, 22/F. Richmond Commercial Building,<br/>109 Argyle Street, Mongkok, Kowloon, Hong Kong',
    'footer.text1': 'Room 1, 1/F, Kam Fai Building, 128-132 Yee Kuk Street, Sham Shui Po, Kowloon, Hong Kong',
    'footer.title2': 'Technical support',
    'footer.text2': 'If you have any questions or technical issues, please fill in the form.',
    'footer.agreements': 'Terms and conditions',
    'footer.agreements.offer': 'Offer',
    'footer.agreements.terms': 'Terms&#160;of&#160;Use',
    'footer.agreements.terms.service': 'Terms&#160;of&#160;Service',
    'footer.agreements.policy': 'Privacy&#160;Policy',
    'footer.agreements.rights': '© 2022 Webtranspay All rights reserved',
    'footer.btn.feedback': 'Request',
    'footer.btn.address': 'Map',

    'textInput.copy': 'Copy',
    'textInput.insert': 'Insert',

    'backLink.back': 'Back',
    'backLink.toMain': 'To home page',

    'account.header.mainPage': 'Main page',
    'account.header.signOut': 'Sign out',
    'account.menu.transfers': 'Transfers',
    'account.menu.send': 'Send',
    'account.menu.receive': 'Receive',
    'account.menu.profile': 'Profile',
    'account.transfers.header': 'All Transfers',
    'account.transfers.table.transactionID': 'Transaction ID',
    'account.transfers.table.created': 'Created',
    'account.transfers.table.transferAmount': 'Transfer amount',
    'account.transfers.table.fee': 'Fee',
    'account.transfers.table.totalAmount': 'Total amount',
    'account.transfers.table.currency': 'Currency',
    'account.transfers.table.status': 'Status',
    'account.transfers.table.card': 'Card',
    'account.transfers.table.cardholder': 'Cardholder',
    'account.transfers.btn.more': 'More',

    'badUser.hello': 'Dear Client,',
    'badUser.message': 'Your account is temporarily blocked. Please contact us at compliance@webtranspay.com for further details.',
    'badUser.wishes': 'Best regards,',
    'badUser.signature': 'Webtranspay team',
}
