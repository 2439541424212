import React from 'react';
import {Paper} from "@material-ui/core";
import ReactHtmlParser from 'react-html-parser';
import WithTranslator from '../../../../providers/WithTranslator';

import './AboutPart.scss';
import background1 from '../../../../resources/about-background1.png';
import background2 from '../../../../resources/about-background2.png';

class AboutPart extends React.Component {
    render() {
        const translator = this.props.translator;
        return (
            <>
                <a name="to_the_sender" />
                <div className='about-part' id='about1PartBlock'>
                    <span className='h2'>{translator('aboutPart.title1')}</span>
                    <div className='container'>
                        <div className='background'>
                            <img src={background1} alt=''/>
                        </div>
                        <Paper className='item'>
                            <span className='h3'>{translator(`aboutPart.card1.title`)}</span>
                            {ReactHtmlParser(translator(`aboutPart.card1.text`))}
                        </Paper>
                        <Paper className='item'>
                            <span className='h3'>{translator(`aboutPart.card2.title`)}</span>
                            {ReactHtmlParser(translator(`aboutPart.card2.text`))}
                        </Paper>
                    </div>
                </div>

                <a name="to_the_recipient" />
                <div className='about-part' id='about2PartBlock'>
                    <span className='h2'>{translator('aboutPart.title2')}</span>
                    <div className='container'>
                        <Paper className='item'>
                            <span className='h3'>{translator(`aboutPart.card3.title`)}</span>
                            {ReactHtmlParser(translator(`aboutPart.card3.text`))}
                        </Paper>
                        <div className='background'>
                            <img src={background2} alt=''/>
                        </div>
                        <Paper className='item'>
                            <span className='h3'>{translator(`aboutPart.card4.title`)}</span>
                            {ReactHtmlParser(translator(`aboutPart.card4.text`))}
                        </Paper>
                    </div>
                </div>
            </>
        )
    }
}

export default WithTranslator(AboutPart);
