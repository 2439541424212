import React from 'react';
import {Link} from "react-router-dom";
import {Button, Paper} from "@material-ui/core";
import apiService from '../../../../services/apiService';
import withTranslator from "../../../../providers/WithTranslator";
import withCheckKYC from "../../../../providers/WithCheckKYC";
import WithTokenSupport from "../../../../providers/WithTokenSupport";
import DialogForm from "../../../common/DialogForm";

import "./ProfilePage.scss"

class ProfilePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            kycStatus: '',
            warning: false
        }
    }

    componentDidMount() {
        this.props.sendRequest(apiService.getProfileInfo).then(response => {
            if (!response?.errors) {
                this.setState(() => {
                    return {
                        firstName: response.first_name,
                        lastName: response.last_name,
                        email: response.email,
                        kycStatus: response.kyc_status,
                        warning: false
                    }
                })
            }
        })
    }

    handleWarning = (show) => {
        this.setState((prevState) => {return {...prevState, warning: show}})
    }

    render() {
        const translator = this.props.translator;

        return (
            <div className='transfer-part account-part container'>
                <div className='transfer'>
                    <span
                        className='header'>{translator('profile.title')}</span>
                    <div className='cards centralize'>

                        <Paper className='card'>
                            <span
                                className='h3 profile-title profile-text-dark'>
                                {translator('profile.personalInfo.title')}
                            </span>
                            <div className='profile-user-info'>
                                <div className='profile-margin-bottom-content'>
                                    <p className='profile-text profile-dark'>
                                        {translator('profile.personalInfo.firstName.title')}
                                    </p>
                                    <p className='profile-text profile-orange'>
                                        {this.state.firstName}
                                    </p>
                                </div>
                                <div className='profile-margin-bottom-content'>
                                    <p className='profile-text profile-dark'>
                                        {translator('profile.personalInfo.lastName.title')}
                                    </p>
                                    <p className='profile-text profile-orange'>
                                        {this.state.lastName}
                                    </p>
                                </div>
                                <div>
                                    <p className='profile-text profile-dark'>
                                        {translator('profile.personalInfo.email.title')}
                                    </p>
                                    <p className='profile-text profile-orange'>
                                        {this.state.email}
                                    </p>
                                </div>
                            </div>
                        </Paper>

                        <Paper className='card'>
                            <span className='h3 profile-title'>
                                {translator('profile.tfa.title')}
                            </span>
                            <div className='profile-margin-bottom'>
                                <div className='inline-fields'>
                                    <p className='profile-text profile-dark'>
                                        {translator('profile.tfa.newDevice.title')}
                                    </p>
                                    <Link className='profile-link profile-orange' to={'/account/show-tfa'}>
                                        {translator('profile.tfa.add.btn')}
                                    </Link>
                                </div>
                                <div className='inline-fields'>
                                    <p className='profile-text profile-dark'>
                                        {translator('profile.tfa.newSecret.title')}
                                    </p>
                                    <a className='profile-link profile-orange'
                                       onClick={() => this.handleWarning(true)}>
                                        {translator('profile.tfa.add.btn')}
                                    </a>
                                </div>
                            </div>
                            <span className='h3 profile-title'>
                                {translator('profile.kyc.title')}
                            </span>
                            <div className='inline-fields'>
                                <p className='profile-text profile-dark'>
                                    {translator('profile.kyc.status.title')}
                                </p>
                                <p className='profile-text profile-orange'>
                                    {this.state.kycStatus}
                                </p>
                            </div>
                        </Paper>
                    </div>

                    <div className='center'>
                        <Link to={'/account/change-password'} >
                            <Button className='primary submit'>
                                {translator('profile.changePassword.btn')}
                            </Button>
                        </Link>
                    </div>
                </div>
                <DialogForm
                    open={this.state.warning}
                    onCancel={() => this.handleWarning(false)}
                    onSubmitPath={'/account/change-tfa'}
                    title={translator('changeTFA.warning.title')}
                    info={translator('changeTFA.warning.info')}
                    submitName={translator('changeTFA.warning.btn.submit')}
                />
            </div>
        )
    }
}

export default withCheckKYC(WithTokenSupport(withTranslator(ProfilePage)));
