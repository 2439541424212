import React from 'react';
import {Button} from "@material-ui/core";
import apiService from '../../../../services/apiService';
import withTranslator from "../../../../providers/WithTranslator";
import {withNavigate} from "../../../../services/hooks"
import withCheckKYC from "../../../../providers/WithCheckKYC";
import withTokenSupport from "../../../../providers/WithTokenSupport";
import {STATUS_COLORS} from "../../../../constants";

import "./TransfersPage.scss";

const columns = [
    { header: "account.transfers.table.transactionID", field: "transaction_id" },
    { header: "account.transfers.table.created", field: "created" },
    { header: "account.transfers.table.transferAmount", field: data => parseFloat(data.amount).toFixed(2) },
    { header: "account.transfers.table.fee", field: data => parseFloat(data.fee).toFixed(2) },
    { header: "account.transfers.table.totalAmount", field: data => (parseFloat(data.amount) + parseFloat(data.fee)).toFixed(2) },
    { header: "account.transfers.table.currency", field: "currency" },
    { header: "account.transfers.table.status", field: "status", color: data => { return STATUS_COLORS[data.status] || "var(--color-account-dark-grey)"} },
    { header: "account.transfers.table.card", field: "card_number" },
    { header: "account.transfers.table.cardholder", field: "card_holder" }
];

const defaultRequestFields = {
    page: 0,
    size: 8
}

class TransfersPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...defaultRequestFields,
            transfers: [],
            next: false,
        }
    }

    loadMore(state) {
        const params = {
            page: state.page + 1,
            size: state.size
        }

        this.props.sendRequest(apiService.getTransferList, params).then((response) => {
            if (!response?.errors){
                this.setState(prev => {return {
                    size: prev.size,
                    page: prev.page + 1,
                    next: response.next,
                    transfers: prev.transfers.concat(response.results)
                }})
            }
        })

    }

    componentDidMount() {
        this.loadMore(this.state);
    }

    handleRowClick = (id) => {
        this.props.navigate(`/account/transfers/${id}`)
    }

    render() {
        const translator = this.props.translator;

        return (
            <div className="transfer-part account-part">
                <span className='header'>{translator("account.transfers.header")}</span>
                <div className="table">
                    <table className="transfers-table">
                        <thead>
                        <tr className='center'>
                            {columns.map((column, i) => (
                                <th key={i}>
                                    {translator(column.header)}
                                </th>
                            ) )}
                        </tr>

                        </thead>
                        <tbody>
                        {this.state.transfers && this.state.transfers.map(item => (
                            <tr className='transfer-row center' key={ item.id } onClick={() => this.handleRowClick(item.id)}>
                                {columns.map((column, i) =>
                                    <td key={`${item.id}_${i}`}
                                        style={{ color: column.color? column.color(item) : "" }}
                                    >
                                        { typeof column.field === "function"
                                            ? column.field(item)
                                            : item[column.field]
                                        }
                                    </td>
                                )}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className={`center  ${!this.state.next ? 'not-visible' : ''}`}>
                    <Button className='primary more' onClick={() => this.loadMore(this.state)}>
                        {translator('account.transfers.btn.more')}
                    </Button>
                </div>
            </div>
        )
    }
}

export default withCheckKYC(withTokenSupport(withNavigate(withTranslator(TransfersPage))));
