import React from 'react';
import {Link} from "react-router-dom";
import {Button, Paper} from '@material-ui/core';
import TextInput from '../../textInpit/TextInput';
import {ReactComponent as IconBack} from "../../../resources/icons/icon-back.svg";
import apiService from '../../../services/apiService';
import WithTranslator from '../../../providers/WithTranslator';
import WithCurrentPage, {pages} from "../../../providers/WithCurrentPage";
import FieldsHelper from '../../common/FieldsHelper';
import FValidator from '../../common/FieldsValidator';
import InfoForm from "../../common/InfoForm";


const defaultFields = {
    email: '',
};

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.FHelper = new FieldsHelper(this);
        this.state = {
            fields: defaultFields,
            errors: {},
            success: false,
        };
    }

    componentDidMount() {
        this.props.setCurrentPage(pages.Other)
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    onSubmit = () => {
        const emailValidObj = FValidator.isEmailValid(this.state.fields.email);
        if (!emailValidObj.valid) {
            this.setState({errors: {email: emailValidObj.detail}});
        } else {
            this.send();
        }
    }

    handleKeyDown = (e) => {
        if (e.key !== 'Enter') return;
        if (!this.isSubmitDisabled()) {
            this.onSubmit();
        }
    }

    isSubmitDisabled = () => {
        const isEmpty = !Object.entries(this.state.fields).every(([k, v]) => !!v)
        const isErrors = Object.values(this.state.errors).some(e => !!e)
        return isEmpty || isErrors;
    }

    send = () => {
        window.scrollTo({top: 0});
        const data = {
            'email': this.state.fields.email
        }
        apiService.sendResetPasswordLink(data).then(state => {
            let new_state = {};
            if (state?.errors) {
                new_state.success = false;
            } else {
                new_state.success = true;
                new_state.errors = {};
            }
            this.setState({...new_state}, () => window.scrollTo({top: 0}))
        });
    }

    render() {
        const translator = this.props.translator;
        return (
            <div className='transfer-part' id='loginPart'>
                <div className='transfer-part__inner'>
                    <div className={`container`}>
                        <Link to={'/'} className='back-link'>
                            <IconBack/>
                            <span>{translator('backLink.toMain')}</span>
                        </Link>
                        <div className='transfer'>
                            <div>
                                <span className='header'>
                                    {translator('loginUserPart.forgotPassword.title')}
                                </span>

                                <div className='cards'>
                                    <Paper className='card'>
                                        <span>{translator('loginUserPart.forgotPassword.info')}</span>
                                        <TextInput label={translator('loginUserPart.form.email')}
                                                   withErrorText
                                                   className='margin-bottom margin-top'
                                                   placeholder='example@email.com'
                                                   value={this.state.fields.email}
                                                   error={!!this.state.errors.detail || !!this.state.errors.email}
                                                   errorText={this.state.errors.detail || this.state.errors.email}
                                                   onChange={this.FHelper.onEmailChange}
                                                   onBlur={this.FHelper.onEmailBlur}
                                        />
                                        <Link to={'/login'} >
                                            {translator('loginUserPart.link.haveAccount')}
                                        </Link><br/>
                                        <Link to={'/register'}>
                                            {translator('loginUserPart.link.dontHaveAccount')}
                                        </Link>
                                    </Paper>
                                </div>
                            </div>
                            <div className='center'>
                                <Button className='submit primary' onClick={this.onSubmit}
                                        disabled={this.isSubmitDisabled()}>
                                    {translator('loginUserPart.forgotPassword.btn.submit')}
                                </Button>
                            </div>
                        </div>
                        <InfoForm
                            open={this.state.success}
                            onClosePath={'/'}
                            title={translator('loginUserPart.forgotPassword.popup.title')}
                            info={translator('loginUserPart.forgotPassword.popup.info')}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default WithCurrentPage(WithTranslator(ForgotPassword));
