import React from 'react';
import {Link} from "react-router-dom";
import {Button, Checkbox, Paper} from '@material-ui/core';
import TextInput from '../textInpit/TextInput';
import Tooltip from "../tooltip/Tooltip";
import WithTranslator from '../../providers/WithTranslator';
import FieldsHelper from './FieldsHelper';
import FValidator from './FieldsValidator';
import TransferCardForm from './TransferCardForm';

import {ReactComponent as IconBack} from '../../resources/icons/icon-back.svg';
import TransferIcons from "./TransferIcons";


const defaultFields = {
    currency: configs.currency,
    amount: '',
    card: '',
    owner: '',
    date: '',
    cvv: '',
    deferPayout: false,
};

class TransferSendFirstStep extends React.Component {
    constructor(props) {
        super(props);

        this.FHelper = new FieldsHelper(this);
        const fields = this.props.fields ? {...this.props.fields} : {...defaultFields};
        this.state = {
            fields,
            errors: {}
        };
    }

    onSubmit = (commission) => {
        const errors = {
            amountError: !FValidator.isAmountValid(this.state.fields.amount),
            cardError: !FValidator.isCardValid(this.state.fields.card),
            ownerError: !FValidator.isOwnerValid(this.state.fields.owner),
            dateError: !FValidator.isDateValid(this.state.fields.date),
            cvvError: !FValidator.isCvvValid(this.state.fields.cvv),
        };
        if (Object.values(errors).some(i => !!i)) {
            this.setState({errors: {...errors}});
        } else {
            this.props.onSubmit({...this.state.fields, fee: commission});
        }
    }

    onCancel = () => {
        this.setState({fields: {...defaultFields}, errors: {}});
        this.props.onCancel();
    }

    render() {
        const translator = this.props.translator;
        const commission = this.state.fields.amount * 0.015;
        return (
            <>
                {/* ToDo временное решение co style, после реализации задачи по состоянию приложения - изменить */}
                <Link to={'/'} className='back-link'
                      style = {{ display: window.location.pathname.includes('account') ? "none" : "" }}
                >
                    <IconBack/>
                    <span>{translator('backLink.toMain')}</span>
                </Link>
                <div>
                    <div>
                        <span className='header'>{translator('transferPart.menu.btn.makeTransfer')}</span>
                        <TransferIcons/>

                        <div className='cards'>

                            <TransferCardForm storage={this}/>

                            <Paper className='card df_fdc_jcsb'>
                                <span className='h3 card-header'>{translator('transferPart.form.amount.title')}</span>

                                <div className='margin-bottom inline-fields'>
                                    <TextInput label={translator('transferPart.form.amount.placeholder')}
                                               placeholder='10 000'
                                               value={this.state.fields.amount}
                                               onChange={(e) => this.FHelper.onAmountChange(e, commission.toFixed(2))}
                                               error={this.state.errors.amountError}
                                               onBlur={this.FHelper.onAmountBlur}/>

                                    {/*ToDo использовать select с value или defaultValue - ошибка в консоли*/}
                                    <TextInput label={translator('transferPart.form.currency.placeholder')}
                                               select
                                               SelectProps={{MenuProps: {disableScrollLock: true}}}
                                               value={this.state.fields.currency}
                                               onChange={this.FHelper.onCurrencyChange}
                                               onBlur={this.FHelper.onAmountBlur}>
                                        {['hkd'].map(value => (
                                            <option key={value} value={value}>
                                                {translator(`transferPart.form.currency.${value}`)}
                                            </option>
                                        ))}
                                    </TextInput>
                                </div>

                                <div className='margin-bottom-auto'>
                                    {translator('transferPart.form.commission1')}
                                    <span className='_primary'>
                                        {commission.toFixed(2)}
                                        {this.state.fields.currency === 'usd' ? ' $' : this.state.fields.currency === 'eur' ? ' €' : ' HK$'}
                                    </span>
                                </div>

                                <div className='agree-part margin-bottom'>
                                    <Checkbox checked={this.state.fields.deferPayout}
                                              onChange={() => this.setState({
                                                  fields: {
                                                      ...this.state.fields,
                                                      fee: commission.toFixed(2),
                                                      deferPayout: !this.state.fields.deferPayout
                                                  }
                                              })}/>
                                    <span className='margin-right _contents'>{translator('transferPart.form.code')}</span>
                                    <Tooltip title={translator('transferPart.form.code.tooltip')}
                                             placement={'top'}
                                             leaveTouchDelay={5000}
                                             enterTouchDelay={10}>
                                        <a src=''>{translator('transferPart.form.code.description')}</a>
                                    </Tooltip>
                                </div>
                            </Paper>
                        </div>
                    </div>
                    <div className='center'>
                        <Button className='primary submit' onClick={() => this.onSubmit(commission.toFixed(2))}
                                disabled={
                                    (!Object.entries(this.state.fields).every(([k, v]) => !!v || k === 'deferPayout')
                                        || Object.values(this.state.errors).some(e => !!e))
                                }>
                            {translator('transferPart.form.btn1.submit')}
                        </Button>
                    </div>
                </div>
            </>
        )
    }
}

export default WithTranslator(TransferSendFirstStep);
