import React from 'react';
import WithTranslator from '../../providers/WithTranslator';

const component = function ({translator, amount, currency}) {
    amount = +amount || 0;
    const commission = amount * 0.015;
    const currencyText = currency === 'usd' ? ' $' : currency === 'eur' ? ' €' : ' HK$'
    return (
        <div className='commission'>
            <div>
                {translator('transferPart.form.commission1')}
                <span className='_primary'>{amount.toFixed(2)}{currencyText}</span>
            </div>
            <div>
                {translator('transferPart.form.commission2')}
                <span className='_primary'>{commission.toFixed(2)}{currencyText}</span>
            </div>
            <hr/>
            <div>
                <b>{translator('transferPart.form.commission3')}</b>
                <b className='_primary'>{(amount + commission).toFixed(2)}{currencyText}</b>
            </div>
        </div>
    );
}

export default WithTranslator(component);
