import FValidator from './FieldsValidator';

export default class FieldsHelper {
    constructor(storage) {
        this.self = storage;
    }

    setField = (name, value, error = false) =>
        this.self.setState({
            fields: {...this.self.state.fields, [name]: value},
            errors: {...this.self.state.errors, [name]: !!error}
        });

    setError = (name, value) =>
        this.self.setState({
            errors: {...this.self.state.errors,[name]: value}
        });

    onEmailChange = e => {
        let email = e.currentTarget.value;
        const state = {
            fields: {...this.self.state.fields, email},
            errors: {...this.self.state.errors, 'email': false, 'detail': false}
        }
        this.self.setState({...state});
    }

    onPasswordChange = e => {
        let password = e.currentTarget.value;
        const state = {
            fields: {...this.self.state.fields, password},
            errors: {...this.self.state.errors, 'password': false, 'detail': false}
        }
        this.self.setState({...state});
    }

    onPasswordConfirmChange = e => {
        let password_confirm = e.currentTarget.value;
        const state = {
            fields: {...this.self.state.fields, password_confirm},
            errors: {...this.self.state.errors, 'password_confirm': false}
        }
        this.self.setState({...state});
    }

    onOldPasswordChange = e => {
        let old_password = e.currentTarget.value;
        const state = {
            fields: {...this.self.state.fields, old_password},
            errors: {...this.self.state.errors, 'old_password': false}
        }
        this.self.setState({...state});
    }

    onNewPasswordChange = e => {
        let new_password = e.currentTarget.value;
        const state = {
            fields: {...this.self.state.fields, new_password},
            errors: {...this.self.state.errors, 'new_password': false}
        }
        this.self.setState({...state});
    }

    onNewPasswordConfirmChange = e => {
        let new_password_conf = e.currentTarget.value;
        const state = {
            fields: {...this.self.state.fields, new_password_conf},
            errors: {...this.self.state.errors, 'new_password_conf': false}
        }
        this.self.setState({...state});
    }

    onEmailBlur = e => {
        let v = e.currentTarget.value;
        let email = FValidator.isEmailValid(v);
        if (!email.valid) {
            this.setError('email', email.detail);
        }
    }

    onPasswordBlur = (e, noCommit) => {
        let v = e.currentTarget.value;
        let password = FValidator.isPasswordValid(v);
        if (!password.valid && noCommit === undefined) {
            this.setError('password', password.detail);
        }
        if (!password.valid && noCommit){
            return {password: password.detail}
        } else return {}
    }



    onRegisterPasswordBlur = e => {
        let errors = this.onPasswordBlur(e, true);
        if (
          this.self.state.errors.password_confirm &&
          this.self.state.fields.password_confirm === e.currentTarget.value
        ){
            errors = {...errors, 'password_confirm': ''}
        }
        if (
          this.self.state.fields.password_confirm &&
          this.self.state.fields.password_confirm !== e.currentTarget.value
        ){
            errors = {...errors, 'password_confirm': 'Passwords don\'t match'}
        }

        this.self.setState({...this.self.state, errors: {...this.self.state.errors, ...errors}})
    }

    onPasswordConfirmBlur = (e, password) => {
        let v = e.currentTarget.value;
        if (password && v !== password) {
            this.setError('password_confirm', 'Passwords don\'t match')
        }
    }

    onNewPasswordBlur = (e) => {
        let v = e.currentTarget.value;
        let new_password = FValidator.isPasswordValid(v);
        if (!new_password.valid) {
            this.setError('new_password', new_password.detail);
        }
    }

    onNewPasswordConfirmBlur = (e, new_password) => {
        let v = e.currentTarget.value;
        if (new_password && v !== new_password) {
            this.setError('new_password_conf', 'Passwords don\'t match')
        }
    }

    onFirstNameBlur = (e) => {
        let v = e.currentTarget.value;
        let name = FValidator.isNameValid(v);
        if (!name.valid) {
            this.setError('first_name', name.detail);
        }
    }

    onLastNameBlur = (e) => {
        let v = e.currentTarget.value;
        let name = FValidator.isNameValid(v);
        if (!name.valid) {
            this.setError('last_name', name.detail);
        }
    }

    onFirstNameChange = e => {
        let first_name = e.currentTarget.value;
        const state = {
            fields: {...this.self.state.fields, first_name},
            errors: {...this.self.state.errors, 'first_name': false}
        }
        this.self.setState({...state});
    }

    onLastNameChange = e => {
        let last_name = e.currentTarget.value;
        const state = {
            fields: {...this.self.state.fields, last_name},
            errors: {...this.self.state.errors, 'last_name': false}
        }
        this.self.setState({...state});
    }

    onKeyChange = e => {
        let key = e.currentTarget.value;
        this.setField('key', key);
    }

    onTfaSecretChange = e => {
        let tfa_secret = e.currentTarget.value;
        this.setField('tfa_secret', tfa_secret);
    }

}
