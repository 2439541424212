import React from 'react';
import ReactHtmlParser from "react-html-parser";
import WithTranslator from '../../../providers/WithTranslator';

import './Footer.scss';
import {Button} from "@material-ui/core";

class Footer extends React.Component {
    openMap = () => {
        // const url = this.props.language === 'ru'
        //     ? 'https://goo.gl/maps/a6pbx17GdpFg6gzZ7'
        //     : 'https://goo.gl/maps/FJiueRD4KvqUdgzMA';

        const url = "https://goo.gl/maps/3ziGxZqQSDS7o5zX7";
        window.open(url);
    }

    openFeedback = () => this.props.openFeedback();

    render() {
        const translator = this.props.translator;
        return (
            <footer className='footer'>
                <div className='item'>
                    <span className='h3'>{ReactHtmlParser(translator('footer.title1'))}</span>
                    <span className='text'>{ReactHtmlParser(translator('footer.text1'))}</span>
                    <Button className='primary' onClick={this.openMap}>
                        {translator('footer.btn.address')}
                    </Button>
                </div>

                <div className='item'>
                    <div>
                        <span className='h3'>{ReactHtmlParser(translator('footer.agreements'))}</span>
                        <a className='link' href={`/terms-webtranspay-${this.props.language}.pdf`} target='_blank'>
                            {ReactHtmlParser(translator('footer.agreements.terms'))}
                        </a>
                        {/*<a className='link' href={`/oferta-webtranspay-${this.props.language}.pdf`} target='_blank'>*/}
                        {/*    {ReactHtmlParser(translator('footer.agreements.offer'))}*/}
                        {/*    </a>*/}
                        <a className='link' href={`/policy-webtranspay-${this.props.language}.pdf`} target='_blank'>
                            {ReactHtmlParser(translator('footer.agreements.policy'))}
                        </a>
                    </div>
                    <div className='rights'>
                        <span className='text'>{translator('footer.agreements.rights')}</span>
                    </div>
                </div>

                <div className='item'>
                    <span className='h3'>{translator('footer.title2')}</span>
                    <span className='text'>{ReactHtmlParser(translator('footer.text2'))}</span>
                    <Button className='primary' onClick={this.openFeedback}>
                        {translator('footer.btn.feedback')}
                    </Button>
                </div>
            </footer>
        )
    }
}

export default WithTranslator(Footer);
