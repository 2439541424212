const CURRENT_YEAR = new Date().getFullYear() % 100;

const isAmountValid = v => /^\d*.?\d{0,2}$/.test(v)
    && +v >= configs.amount[configs.currency].min
    && +v <= configs.amount[configs.currency].max;

const isCardValid = v => {
    const value = v.replace(/\s/g, '')
    if (!/^\d{15,19}$/.test(value))
        return false;

    let sum = 0;
    const digits = value.split('');
    for (let i = 0; i < digits.length; i++) {
        let cardNum = parseInt(digits[i]);

        if ((digits.length - i) % 2 === 0) {
            cardNum = cardNum * 2;
            if (cardNum > 9)
                cardNum = cardNum - 9;
        }
        sum += cardNum;
    }

    return sum % 10 === 0;
}

const isOwnerValid = v => /^[A-Z]+[ ][A-Z]+$/.test(v)

const isDateValid = v => {
    const [month, year] = v.split('/');
    return isMonthValid(month) && isYearValid(year);
}

const isMonthValid = v => +v > 0 && +v < 13;

const isYearValid = v => +v >= CURRENT_YEAR && +v < 99;

const isCvvValid = v => /^\d{3}$/.test(v);

export default {
    isAmountValid,
    isCardValid,
    isOwnerValid,
    isDateValid,
    isMonthValid,
    isYearValid,
    isCvvValid
}