import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Paper} from '@material-ui/core';
import TextInput from '../../textInpit/TextInput';
import {ReactComponent as IconBack} from '../../../resources/icons/icon-back.svg';
import WithTranslator from '../../../providers/WithTranslator';
import WithCurrentPage, {pages} from '../../../providers/WithCurrentPage';
import apiService from '../../../services/apiService';
import FieldsHelper from '../../common/FieldsHelper';
import FValidator from '../../common/FieldsValidator';
import CommonPopup from '../popups/CommonPopup';


const defaultFields = {
    password: '',
    password_confirm: '',
};

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.FHelper = new FieldsHelper(this);
        this.state = {
            fields: defaultFields,
            errors: {},
            success: false,
            data: {},

            isSignatureExpiredError: false,
            isSignatureInvalidError: false,
        };
    }

    componentDidMount() {
        this.props.setCurrentPage(pages.Other)
        const params = window.location.search.substring(1);
        if (params) {
            const searchParams = new URLSearchParams(params);
            const data = {
                'user_id': searchParams.get('user_id'),
                'timestamp': searchParams.get('timestamp'),
                'signature': searchParams.get('signature')
            }
            if (Object.keys(data).length === 3) {
                this.setState((prevState) => ({ ...prevState, data: data}), () => window.scrollTo({top: 0}));
            }
        }
        document.addEventListener('keydown', this.handleKeyDown)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    onSubmit = () => {
        const password_confirm = this.state.fields.password_confirm
        const password = this.state.fields.password
        const errors = {
            password: FValidator.isPasswordValid(password).detail,
            password_confirm: password_confirm !== password ? 'Passwords don\'t match' : '',
        };
        if (Object.values(errors).some(i => !!i)) {
            this.setState((prevState) => ({ ...prevState, errors: {...errors} }));
        } else {
            this.send();
        }
    }

    handleKeyDown = (e) => {
        if (e.key !== 'Enter') return;
        if (!this.isSubmitDisabled()) {
            this.onSubmit();
        }
    }

    isSubmitDisabled = () => {
        const isEmpty = !Object.entries(this.state.fields).every(([k, v]) => !!v)
        const isErrors = Object.values(this.state.errors).some(e => !!e)
        return isEmpty || isErrors;
    }

    send = () => {
        window.scrollTo({top: 0});
        const data = this.state.data
        data.password = this.state.fields.password
        data.password_confirm = this.state.fields.password_confirm

        apiService.resetPassword(data).then(state => {
            let new_state = {};
            if (state?.errors) {
                new_state.success = false;
                if (state?.errors?.detail === 'Signature expired') {
                    new_state.isSignatureExpiredError = true;
                }
                if (state?.errors?.detail === 'Invalid signature') {
                    new_state.isSignatureInvalidError = true;
                }
            } else {
                new_state.success = true;
                new_state.errors = {};
            }
            this.setState((prevState) => ({ ...prevState, ...new_state}), () => window.scrollTo({top: 0}))
        });
    }

    handleClosePopup = () => {
        this.setState({
            isSignatureExpiredError: false,
            isSignatureInvalidError: false,
        })
    }

    render() {
        const translator = this.props.translator;
        return (
            <div className='transfer-part' id='loginPart'>
                <div className='transfer-part__inner'>
                    <div className={`container`}>
                        <Link to={'/'} className='back-link'>
                            <IconBack/>
                            <span>{translator('backLink.toMain')}</span>
                        </Link>
                        <div className='transfer'>
                            <div>
                                <span className='header'>
                                    {translator('loginUserPart.resetPassword.title')}
                                </span>

                                <div className='cards'>
                                    <Paper className='card'>
                                        <TextInput label={translator('loginUserPart.form.newPassword')}
                                                   type='password' autoComplete='new-password' withErrorText
                                                   className='margin-bottom' id='password'
                                                   placeholder='**********'
                                                   error={!!this.state.errors.password}
                                                   errorText={this.state.errors.password}
                                                   value={this.state.fields.password}
                                                   onChange={this.FHelper.onPasswordChange}
                                                   onBlur={this.FHelper.onRegisterPasswordBlur}/>
                                        <TextInput label={translator('loginUserPart.form.confirmNewPassword')}
                                                   type='password' autoComplete='new-password' withErrorText
                                                   className='margin-bottom' id='password_confirm'
                                                   placeholder='**********'
                                                   error={!!this.state.errors.password_confirm}
                                                   errorText={this.state.errors.password_confirm}
                                                   value={this.state.fields.password_confirm}
                                                   onChange={this.FHelper.onPasswordConfirmChange}
                                                   onBlur={(e) => {this.FHelper.onPasswordConfirmBlur(e, this.state.fields.password)}}/>
                                    </Paper>
                                </div>
                            </div>
                            <div className='center'>
                                <Button className='submit primary' onClick={this.onSubmit}
                                        disabled={this.isSubmitDisabled()}>
                                    {translator('loginUserPart.resetPassword.btn.submit')}
                                </Button>
                            </div>
                        </div>
                        <CommonPopup
                            success={{
                                isSuccess: this.state.success,
                                title: translator('loginUserPart.resetPassword.popup.title'),
                                info: translator('loginUserPart.resetPassword.popup.info'),
                                onClosePath: '/login',
                            }}
                            error={{
                                isSignatureExpiredError: this.state.isSignatureExpiredError,
                                isSignatureInvalidError: this.state.isSignatureInvalidError,
                                title: translator('loginUserPart.resetPassword.popup.error.title'),
                                expiredInfo: translator('loginUserPart.resetPassword.popup.error.signatureExpired'),
                                info: translator('loginUserPart.resetPassword.popup.error.signatureError'),
                                onClose: this.handleClosePopup,
                                onClosePath: '/'
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default WithCurrentPage(WithTranslator(ResetPassword));
